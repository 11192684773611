import React from "react";
import { useState } from "react";
import "./navbar.css";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProyectos } from "../../redux/actions";

export const Navbar = () => {
  const dispatch = useDispatch();
  const [clicked, setClicked] = useState(false);
  const [proyectosM, setProyectosM] = useState(false);

  const [identif, setIdentif] = useState(false);
  const { pathname } = useLocation();

  // const proyectos = useSelector((state) => state.Proyectos.reverse())
  const proyectos = useSelector((state) => state.Proyectos); //////////////OJOOOOOOOOOOOOOOOOO

  useEffect(() => {
    dispatch(getProyectos());
  }, []);

  useEffect(() => {
    setClicked(false);
    if (window.localStorage.getItem("usuario") === "1") {
      setIdentif(true);
    }
  }, [pathname]);

  const mostrarproyectos = () => {
    if (proyectosM) {
      setProyectosM(false);
    } else {
      setProyectosM(true);
    }
  };
  const clicknav = () => {
    if (clicked) {
      setClicked(false);
    } else {
      setClicked(true);
    }
  };

  return (
    <nav className="navbar">
      <Link className="logonavbar" to="/">
        <img
          className="imglogonavbar"
          src="../../../image/logocompleto.png"
          alt=""
        />
      </Link>
      <>
        <ul id="ulnavbar" className={clicked ? "#ulnavbar active" : "#navbar"}>
          <li className="ulnavbarli ">
            <Link className="dropdown-item ulnavbarlia" to="/nosotros">
              Nosotros
            </Link>
          </li>
          <li className="dropdown ulnavbarli">
            <a
              className="ulnavbarlia"
              href="#"
              role="button"
              id="dropdownMenuLink"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Proyectos
            </a>

            <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
              {proyectos && proyectos.length > 0 && (
                <>
                  {proyectos.map((e) => {
                    if (e.id === 22) {
                      return (
                        <li key={e.id}>
                          <Link
                            className="dropdown-item"
                            to={"/proyectos/" + e.name}
                          >
                            {e.title}
                          </Link>
                        </li>
                      );
                    }
                    return null;
                  })}
                  {proyectos.map((e) => {
                    if (e.id === 21) {
                      return (
                        <li key={e.id}>
                          <Link
                            className="dropdown-item"
                            to={"/proyectos/" + e.name}
                          >
                            {e.title}
                          </Link>
                        </li>
                      );
                    }
                    return null;
                  })}
                  {proyectos.map((e) => {
                    if (e.id === 20) {
                      return (
                        <li key={e.id}>
                          <Link
                            className="dropdown-item"
                            to={"/proyectos/" + e.name}
                          >
                            {e.title}
                          </Link>
                        </li>
                      );
                    }
                    return null;
                  })}
                  {proyectos.map((e) => {
                    if (e.id === 19) {
                      return (
                        <li key={e.id}>
                          <Link
                            className="dropdown-item"
                            to={"/proyectos/" + e.name}
                          >
                            {e.title}
                          </Link>
                        </li>
                      );
                    }
                    return null;
                  })}
                  {proyectos.map((e) => {
                    if (e.id === 18) {
                      return (
                        <li key={e.id}>
                          <Link
                            className="dropdown-item"
                            to={"/proyectos/" + e.name}
                          >
                            {e.title}
                          </Link>
                        </li>
                      );
                    }
                    return null;
                  })}
                  {proyectos.map((e) => {
                    if (e.id === 17) {
                      return (
                        <li key={e.id}>
                          <Link
                            className="dropdown-item"
                            to={"/proyectos/" + e.name}
                          >
                            {e.title}
                          </Link>
                        </li>
                      );
                    }
                    return null;
                  })}
                  {proyectos.map((e) => {
                    if (e.id === 16) {
                      return (
                        <li key={e.id}>
                          <Link
                            className="dropdown-item"
                            to={"/proyectos/" + e.name}
                          >
                            {e.title}
                          </Link>
                        </li>
                      );
                    }
                    return null;
                  })}
                  {proyectos.map((e) => {
                    if (e.id === 15) {
                      return (
                        <li key={e.id}>
                          <Link
                            className="dropdown-item"
                            to={"/proyectos/" + e.name}
                          >
                            {e.title}
                          </Link>
                        </li>
                      );
                    }
                    return null;
                  })}
                  {proyectos.map((e) => {
                    if (e.id === 14) {
                      return (
                        <li key={e.id}>
                          <Link
                            className="dropdown-item"
                            to={"/proyectos/" + e.name}
                          >
                            {e.title}
                          </Link>
                        </li>
                      );
                    }
                    return null;
                  })}
                  {proyectos.map((e) => {
                    if (e.id === 13) {
                      return (
                        <li key={e.id}>
                          <Link
                            className="dropdown-item"
                            to={"/proyectos/" + e.name}
                          >
                            {e.title}
                          </Link>
                        </li>
                      );
                    }
                    return null;
                  })}
                  {proyectos.map((e) => {
                    if (e.id === 12) {
                      return (
                        <li key={e.id}>
                          <Link
                            className="dropdown-item"
                            to={"/proyectos/" + e.name}
                          >
                            {e.title}
                          </Link>
                        </li>
                      );
                    }
                    return null;
                  })}
                  {proyectos.map((e) => {
                    if (e.id === 5) {
                      return (
                        <li key={e.id}>
                          <Link
                            className="dropdown-item"
                            to={"/proyectos/" + e.name}
                          >
                            {e.title}
                          </Link>
                        </li>
                      );
                    }
                    return null;
                  })}
                  {proyectos.map((e) => {
                    if (e.id === 6) {
                      return (
                        <li key={e.id}>
                          <Link
                            className="dropdown-item"
                            to={"/proyectos/" + e.name}
                          >
                            {e.title}
                          </Link>
                        </li>
                      );
                    }
                    return null;
                  })}
                  {proyectos.map((e) => {
                    if (e.id === 11) {
                      return (
                        <li key={e.id}>
                          <Link
                            className="dropdown-item"
                            to={"/proyectos/" + e.name}
                          >
                            {e.title}
                          </Link>
                        </li>
                      );
                    }
                    return null;
                  })}
                </>
              )}
            </ul>
          </li>
          <li className="ulnavbarli">
            <Link className="ulnavbarlia dropdown-item" to="/historia">
              Historia
            </Link>
          </li>
          <li className="ulnavbarli navizq">
            <Link className="ulnavbarlia dropdown-item" to="/contacto">
              Contacto
            </Link>
          </li>
          {identif ? (
            <li className="ulnavbarli navizq">
              <Link
                className="ulnavbarlia dropdown-item"
                to="../../login/paneladmin"
              >
                Admin
              </Link>
            </li>
          ) : (
            ""
          )}
        </ul>
      </>
      <div id="mobilenavbar" onClick={clicknav}>
        <i id="bar" className={clicked ? "fas fa-times" : "fas fa-bars"}></i>
        {/* <i className="fas fa-bars"></i>
        <i className="fas fa-times"></i> */}
      </div>
    </nav>
  );
};
