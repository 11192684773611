import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getProyectosById } from "../../redux/actions";
import Iframe from "react-iframe";
import { Slicesproyecto } from "./slicesproyecto/Slicesproyecto";
import "./proyecto.css";
import ReactGA from "react-ga4";

export const Proyecto = () => {
  const { name } = useParams();
  const dispatch = useDispatch();
  const proyecto = useSelector((state) => state.ProyectoById);
  const [width, setWidth] = useState(window.innerWidth);
  const [images, setImages] = useState([]);

  //   const handleResize = () => {
  //     setWidth(window.innerWidth);
  //   };

  //   useEffect(() => {
  //     window.addEventListener("resize", handleResize);
  //   }, []);

  useEffect(() => {
    dispatch(getProyectosById(name));
    ReactGA.send({ hitType: "pageview", page: name, title: name.toLocaleUpperCase() });
  }, [name]);

  // console.log(proyecto)

  useEffect(() => {
    if (proyecto.fotos) {
      cargImg();
    }
  }, [proyecto]);

  const cargImg = () => {
    var img = [];

    if (width > 750) {
      for (let i = 0; i < proyecto.fotos.desktop.length; i++) {
        var obj = {
          original: "",
          originalTitle: "",
          thumbnail: "",
        };
        obj.original = proyecto.fotos.desktop[i];
        obj.originalTitle = proyecto.title + [i];
        obj.thumbnail = proyecto.fotos.desktop[i];
        img.push(obj);
      }
    } else {
      for (let i = 0; i < proyecto.fotos.desktop.length; i++) {
        var obj = {
          original: "",
          originalTitle: "",
          thumbnail: "",
        };
        obj.original = proyecto.fotos.mobile[i];
        obj.originalTitle = proyecto.title + [i];
        obj.thumbnail = proyecto.fotos.mobile[i];
        img.push(obj);
      }
    }

    setImages(img);
  };

  



  let shareData = {
    title: proyecto.title,
    text: 'Mira este proyecto',
    url: window.location.href,
  }

  const compartir = (e)=>{
    const resultPara = e
  
  if (!navigator.canShare) {
    
    alert("no se puede compartir")
  }
  else if (navigator.canShare(shareData)) {
    console.log(shareData)
    navigator.share(shareData)
  } else {
    alert("no se puede compartir")
  }
  }


  console.log(proyecto)

  

  if (proyecto && proyecto.name) {
    return (
      <div className="contenedor__proyecto ">
        
        <section className="seccion__banner__proyecto container__proyecto">
          {proyecto.bannerproyecto.length > 5?
          <img className="logobanner__proyecto" src={proyecto.bannerproyecto} alt="" />
          :
          <h1 className="p_titulos_home">{proyecto.title}</h1>
          
        
        }
        </section>
        <section className="slices__proyecto ">
          <Slicesproyecto images={images}></Slicesproyecto>
        </section>


        
        <section className="primertexto__proyecto">
          <div className="main__content__proyecto">
            <h1 className="h1__proyecto titulos">{proyecto.titulo1}</h1>
            <p className="p__proyecto parrafos">{proyecto.texto1}</p>
          </div>
        </section>


        
        <section className="primerafoto__proyecto">
          {width >= 751 && proyecto.foto1desc ? (
            <img src={proyecto.foto1desc} alt="" />
          ) : (
            ""
          )}
          {width <= 750 && proyecto.foto1mob ? (
            <img src={proyecto.foto1mob} alt="" />
          ) : (
            ""
          )}
        </section>


        
        <section className="segundotexto__proyecto">
          <h2 className="h1__proyecto titulos">{proyecto.titulo2}</h2>
          <p className="p__proyecto parrafos">{proyecto.texto2}</p>

        </section>


        
        <section className="segundafoto__proyecto">
          {width >= 751 && proyecto.foto2desc ? (
            <img src={proyecto.foto2desc} alt="" />
          ) : (
            ""
          )}
          {width <= 750 && proyecto.foto2mob ? (
            <img src={proyecto.foto2mob} alt="" />
          ) : (
            ""
          )}
        </section>
        <section className="ubicacion__proyecto">
          <h2 className="h1__proyecto ">{proyecto.tituloubicacion}</h2>
          <p className="p__proyecto parrafos">{proyecto.ubicacion}</p>
          {proyecto.iconos.residencia ? (
            <p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="currentColor"
                className="bi bi-buildings-fill"
                viewBox="0 0 16 16"
              >
                <path d="M15 .5a.5.5 0 0 0-.724-.447l-8 4A.5.5 0 0 0 6 4.5v3.14L.342 9.526A.5.5 0 0 0 0 10v5.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V14h1v1.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5V.5ZM2 11h1v1H2v-1Zm2 0h1v1H4v-1Zm-1 2v1H2v-1h1Zm1 0h1v1H4v-1Zm9-10v1h-1V3h1ZM8 5h1v1H8V5Zm1 2v1H8V7h1ZM8 9h1v1H8V9Zm2 0h1v1h-1V9Zm-1 2v1H8v-1h1Zm1 0h1v1h-1v-1Zm3-2v1h-1V9h1Zm-1 2h1v1h-1v-1Zm-2-4h1v1h-1V7Zm3 0v1h-1V7h1Zm-2-2v1h-1V5h1Zm1 0h1v1h-1V5Z" />
              </svg>
              ZONA RESIDENCIAL
            </p>
          ) : (
            ""
          )}
          {proyecto.iconos.oficina ? (
            <p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="currentColor"
                className="bi bi-person-workspace"
                viewBox="0 0 16 16"
              >
                <path d="M4 16s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H4Zm4-5.95a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                <path d="M2 1a2 2 0 0 0-2 2v9.5A1.5 1.5 0 0 0 1.5 14h.653a5.373 5.373 0 0 1 1.066-2H1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v9h-2.219c.554.654.89 1.373 1.066 2h.653a1.5 1.5 0 0 0 1.5-1.5V3a2 2 0 0 0-2-2H2Z" />
              </svg>
              OFICINAS
            </p>
          ) : (
            ""
          )}
          {proyecto.iconos.comercial ? (
            <p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="currentColor"
                className="bi bi-shop"
                viewBox="0 0 16 16"
              >
                <path d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.371 2.371 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976l2.61-3.045zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0zM1.5 8.5A.5.5 0 0 1 2 9v6h1v-5a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v5h6V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5zM4 15h3v-5H4v5zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-3zm3 0h-2v3h2v-3z" />
              </svg>
              LOCALES COMERCIALES
            </p>
          ) : (
            ""
          )}
          {proyecto.iconos.mercado ? (
            <p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="currentColor"
                className="bi bi-graph-up-arrow"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M0 0h1v15h15v1H0V0Zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5Z"
                />
              </svg>
              MERCADO
            </p>
          ) : (
            ""
          )}
          {proyecto.iconos.poderadquisitivo ? (
            <p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="currentColor"
                className="bi bi-piggy-bank-fill"
                viewBox="0 0 16 16"
              >
                <path d="M7.964 1.527c-2.977 0-5.571 1.704-6.32 4.125h-.55A1 1 0 0 0 .11 6.824l.254 1.46a1.5 1.5 0 0 0 1.478 1.243h.263c.3.513.688.978 1.145 1.382l-.729 2.477a.5.5 0 0 0 .48.641h2a.5.5 0 0 0 .471-.332l.482-1.351c.635.173 1.31.267 2.011.267.707 0 1.388-.095 2.028-.272l.543 1.372a.5.5 0 0 0 .465.316h2a.5.5 0 0 0 .478-.645l-.761-2.506C13.81 9.895 14.5 8.559 14.5 7.069c0-.145-.007-.29-.02-.431.261-.11.508-.266.705-.444.315.306.815.306.815-.417 0 .223-.5.223-.461-.026a.95.95 0 0 0 .09-.255.7.7 0 0 0-.202-.645.58.58 0 0 0-.707-.098.735.735 0 0 0-.375.562c-.024.243.082.48.32.654a2.112 2.112 0 0 1-.259.153c-.534-2.664-3.284-4.595-6.442-4.595Zm7.173 3.876a.565.565 0 0 1-.098.21.704.704 0 0 1-.044-.025c-.146-.09-.157-.175-.152-.223a.236.236 0 0 1 .117-.173c.049-.027.08-.021.113.012a.202.202 0 0 1 .064.199Zm-8.999-.65a.5.5 0 1 1-.276-.96A7.613 7.613 0 0 1 7.964 3.5c.763 0 1.497.11 2.18.315a.5.5 0 1 1-.287.958A6.602 6.602 0 0 0 7.964 4.5c-.64 0-1.255.09-1.826.254ZM5 6.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" />
              </svg>
              PODER ADQUISITIVO
            </p>
          ) : (
            ""
          )}
          {proyecto.iconos.ubicacion ? (
            <p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="currentColor"
                className="bi bi-geo-alt-fill"
                viewBox="0 0 16 16"
              >
                <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
              </svg>
              UBICACION
            </p>
          ) : (
            ""
          )}
          {proyecto.iconos.vacacional ? (
            <p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="currentColor"
                className="bi bi-globe-americas"
                viewBox="0 0 16 16"
              >
                <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0ZM2.04 4.326c.325 1.329 2.532 2.54 3.717 3.19.48.263.793.434.743.484-.08.08-.162.158-.242.234-.416.396-.787.749-.758 1.266.035.634.618.824 1.214 1.017.577.188 1.168.38 1.286.983.082.417-.075.988-.22 1.52-.215.782-.406 1.48.22 1.48 1.5-.5 3.798-3.186 4-5 .138-1.243-2-2-3.5-2.5-.478-.16-.755.081-.99.284-.172.15-.322.279-.51.216-.445-.148-2.5-2-1.5-2.5.78-.39.952-.171 1.227.182.078.099.163.208.273.318.609.304.662-.132.723-.633.039-.322.081-.671.277-.867.434-.434 1.265-.791 2.028-1.12.712-.306 1.365-.587 1.579-.88A7 7 0 1 1 2.04 4.327Z" />
              </svg>
              
                APTO TURISMO
            </p>
          ) : (
            ""
          )}
          {proyecto.iconos.profesional ? (
            <p>
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-person-fill" viewBox="0 0 16 16">
  <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"/>
</svg>
APTO PROFESIONAL
            </p>
          ) : (
            ""
          )}
          {proyecto.iconos.cochera ? (
            <p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="currentColor"
                className="bi bi-car-front-fill"
                viewBox="0 0 16 16"
              >
                <path d="M2.52 3.515A2.5 2.5 0 0 1 4.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679c.033.161.049.325.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 0 1 .049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.807.807 0 0 0 .381-.404l.792-1.848ZM3 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm10 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM6 8a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2H6ZM2.906 5.189a.51.51 0 0 0 .497.731c.91-.073 3.35-.17 4.597-.17 1.247 0 3.688.097 4.597.17a.51.51 0 0 0 .497-.731l-.956-1.913A.5.5 0 0 0 11.691 3H4.309a.5.5 0 0 0-.447.276L2.906 5.19Z" />
              </svg>
              COCHERA
            </p>
          ) : (
            ""
          )}
          {proyecto.iconos.cocherasubterranea ? (
            <p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="currentColor"
                className="bi bi-car-front-fill"
                viewBox="0 0 16 16"
              >
                <path d="M2.52 3.515A2.5 2.5 0 0 1 4.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679c.033.161.049.325.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 0 1 .049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.807.807 0 0 0 .381-.404l.792-1.848ZM3 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm10 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM6 8a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2H6ZM2.906 5.189a.51.51 0 0 0 .497.731c.91-.073 3.35-.17 4.597-.17 1.247 0 3.688.097 4.597.17a.51.51 0 0 0 .497-.731l-.956-1.913A.5.5 0 0 0 11.691 3H4.309a.5.5 0 0 0-.447.276L2.906 5.19Z" />
              </svg>
              COCHERA SUBTERRANEA
            </p>
          ) : (
            ""
          )}
          {proyecto.iconos.pavimento ? (
            <p>
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-signpost-split-fill" viewBox="0 0 16 16">
  <path d="M7 16h2V6h5a1 1 0 0 0 .8-.4l.975-1.3a.5.5 0 0 0 0-.6L14.8 2.4A1 1 0 0 0 14 2H9v-.586a1 1 0 0 0-2 0V7H2a1 1 0 0 0-.8.4L.225 8.7a.5.5 0 0 0 0 .6l.975 1.3a1 1 0 0 0 .8.4h5v5z"/>
</svg>
              CALLE PAVIMENTADA
            </p>
          ) : (
            ""
          )}
          {proyecto.textoiconos && proyecto.textoiconos.length > 0 ?
          proyecto.textoiconos.map((p)=>
          <ul className="parrafos">
          <li >{p}</li>
        </ul>
          )
          :""
        }
          
        </section>
        <section className="map__proyecto">
            <Iframe
          src={proyecto.map}
          width="100%"
          height="450"
          style="border:0;"
          allowfullscreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
            ></Iframe>

        </section>
        <section className="youtube__proyecto">

          {proyecto.youtube.length > 5 ?
          <Iframe
            width="100%"
            height="315"
            src={proyecto.youtube}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></Iframe>
          :""
          
        
        }

        </section>

        <section className="seccion__botones__proyecto">
          {proyecto.brochure.length > 1?
          <button className="boton__proyecto"><a href={proyecto.brochure} target="_blank">BROCHURE</a></button>
          :""
          }
          <button className="boton__proyecto" onClick={compartir}>COMPARTIR</button>
        </section>



      </div>
    );
  } else {
    return <div>Cargando</div>;
  }
};
