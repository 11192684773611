import React, { useEffect } from "react";
import "./nosotros.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import ReactGA from "react-ga4";

export const Nosotros = () => {

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "nostoros", title: "NOSOTROS" });
  }, [])





  const Settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 10,
    className: "logos__empresas__nosotros",
    cssEase: "linear"
  };


  return (
    <main className="main__nosotros">
      <section className="seccion1__nosotros">
        <div className="div__foto__nosotros"></div>
        {/* <div className="div__primer__texto__nosotros">
          <h1 className="h1__texto__nosotros">NUESTRO EQUIPO DE PROFESIONALES</h1>
          <p className="p__texto__nosotros">
          Nos ocupamos de integrar talentos competentes de distintas áreas a nuestro equipo de profesionales para abarcar con éxito todas las etapas de la ingeniería y la construcción civil, desde el inicio del proyecto y su diseño arquitectónico hasta la entrega de los inmuebles.
          </p>
        </div> */}
      </section>
      <div className="div__primer__texto__nosotros div_primer_texto_nosotros_dos">
          <h1 className="h1__texto__nosotros titulos">NUESTRO EQUIPO DE PROFESIONALES</h1>
          <p className="p__texto__nosotros parrafos">
          Nos ocupamos de integrar talentos competentes de distintas áreas a nuestro equipo de profesionales para abarcar con éxito todas las etapas de la ingeniería y la construcción civil, desde el inicio del proyecto y su diseño arquitectónico hasta la entrega de los inmuebles.
          </p>
        </div>
      <section className="seccion2__nosotros">
        <div className="div__segundo__texto__nosotros">
          <h2 className="h1__texto__nosotros titulos">SOLIDEZ Y TRAYECTORIA</h2>
          <p className="p__texto__nosotros parrafos">
          Nuestra experiencia y profesionalismo nos ha permitido posicionarnos en la industria de la construcción a lo largo de estos 25 años de trayectoria, como una de las compañías líderes en el mercado.<br></br>
          En este tiempo, hemos llevado adelante una importante cantidad de obras para empresas, particulares y entidades públicas.<br></br>
Hoy en día nos enfocamos en gestionar y coordinar la planificación integral de cada proyecto, elaboramos el plan de negocios, aseguramos las fuentes de financiamiento, coordinamos las estrategias de mercadeo y ventas, supervisamos desde la construcción hasta el último detalle en relación a la entrega de los inmuebles. 

          </p>
        </div>
        <div className="div__foto__nosotros2"></div>
      </section>
      <section className="seccion3__nosotros">
        
        <div className="div__tercero__texto__nosotros">
          <h2 className="h1__texto__nosotros padding35 titulos">QUIENES NOS RECOMIENDAN</h2>
          <Slider {...Settings}>
              <div className= "divslick">
                <img src="./image/empresas/imps-on.jpg" alt="" />
              </div>
              <div className= "divslick">
                <img src="./image/empresas/ministerio-de-turismo-on.jpg" alt="" />
              </div>
              <div className= "divslick">
                <img src="./image/empresas/nao-on.jpg" alt="" />
              </div>
              <div className= "divslick">
                <img src="./image/empresas/neuquen-on.jpg" alt="" />
              </div>
              <div className= "divslick">
                <img src="./image/empresas/sidoni-on.jpg" alt="" />
              </div>
              <div className= "divslick">
                <img src="./image/empresas/apn-on.jpg" alt="" />
              </div>
            </Slider>

        </div>
      </section>
      
    </main>
  );
};
