import * as actionTypes from "../action-types/";

const initialState = {
  Proyectos: [],
  ProyectoById: {},
  UserLogin: {},
  Suscriptores: []
};


const rootReducer = (state = initialState, action) => {


  switch (action.type) {
    case actionTypes.GET_PROYECTOS:
      return {
        ...state,
        Proyectos: action.payload,
      };
    case actionTypes.GET_PROYECTOS_BY_ID:
      return {
        ...state,
        ProyectoById: action.payload,
      };
    case actionTypes.LOGIN:
      return {
        ...state,
        UserLogin: action.payload,
      };
    case actionTypes.GET_SUSCRIPTORES:
      return {
        ...state,
        Suscriptores: action.payload,
      };

    default:
      return state;
  }
};

export default rootReducer;
