import React, { useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import './suscriptores.css'
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { actualizarSuscriptor, createSuscriptor, eliminarSuscriptor } from '../../redux/actions';

export const Suscriptoresadmin = ({suscriptores}) => {
  const dispatch = useDispatch();



  const [input, setInput] = useState({
    id: "",
    apellido: "",
    email: "",
    nombre: "",
    telefono: null,
  });
  const [show, setShow] = useState(false);
  const [showCrear, setShowCrear] = useState(false);
  const [showEliminar, setShowEliminar] = useState(false);

    const userColumns = [
        { field: "id", headerName: "ID", width: 70 },
        {
          field: "nombre",
          headerName: "Nombre",
          width: 230,
        },
        {
          field: "apellido",
          headerName: "Apellido",
          width: 230,
        },
        {
          field: "email",
          headerName: "Email",
          width: 230,
        },      
        {
          field: "telefono",
          headerName: "Telefono",
          width: 120,
        },
        // {
        //   field: "status",
        //   headerName: "Status",
        //   width: 160,
        //   renderCell: (params) => {
        //     return (
        //       <div className={`cellWithStatus ${params.row.status}`}>
        //         {params.row.status}
        //       </div>
        //     );
        //   },
        // },
        {
          field: "action",
          headerName: "Action",
          width: 250,
          renderCell: (params) => {
            return (
              <div className="cellAction">
                
                <button className="btn btn-primary"
                    onClick={(x)=>modificarUsuario(params.row)}
                  >
                    MODIFICAR
                  </button>
                <button
                  className="btn btn-danger"
                  style={{marginLeft: 10}}
                  onClick={(x) => eliminarUsuario(params.row.id)}
                >
                  Delete
                </button>
              </div>
            );
          },
        },
      ];


      const modificarUsuario = (e)=>{
        setInput(e)
        setShow(true)
      }
      const eliminarUsuario = (e)=>{
        setInput({
          ...input,
          id: e,
        })
        setShowEliminar(true)
      }

      


      const handleInputChange = function (e) {
        setInput({
          ...input,
          [e.target.name]: e.target.value,
        })
        
      };

      const handleClose = () => {
        setShow(false)
      }
      const handleCloseCrear = () => {
        setShowCrear(false)
        
      }

      const handleSave = ()=>{
        dispatch(actualizarSuscriptor(input))
        setTimeout(function(){
          setShow(false)
        }, 100);
      }
      const handleSaveCrear = ()=>{
        dispatch(createSuscriptor(input))
        setTimeout(function(){
          setShowCrear(false)
        }, 100);
      }
      const handleSaveEliminar = ()=>{
        dispatch(eliminarSuscriptor(input))
        setTimeout(function(){
          setShowCrear(false)
        }, 100);
      }






  return (
    
    <div className="listContainer">
      <div className="datatable">
      <div className="datatableTitle "style={{padding: 20}}>
        <h1>SUSCRIPTORES</h1>
        <button className='btn btn-success' onClick={()=>setShowCrear(true)}>CREAR SUSCRIPTOR</button>
      </div>
      </div>
    <div style={{height: 700, padding: 20}}>      
      <DataGrid
    className="datagrid"
    columns={userColumns}
    rows={suscriptores}
    pageSize={10}
    rowsPerPageOptions={[10]}
    slots={{ toolbar: GridToolbar }}
    // checkboxSelection
  /></div>









<Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Modificar USUARIO</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <label>Email: .</label>
              <input
                type="text"
                name="email"
                onChange={handleInputChange}
                defaultValue={input.email}
              />
            </div>
            <div>
              <label>Apellido: .</label>
              <input
                type="text"
                name="apellido"
                onChange={handleInputChange}
                defaultValue={input.apellido}
              />
            </div>
            <div>
              <label>Nombre: .</label>
              <input
                type="text"
                name="nombre"
                onChange={handleInputChange}
                defaultValue={input.nombre}
              />
            </div>
            <div>
              <label>Telefono: .</label>
              <input
                type="text"
                name="telefono"
                onChange={handleInputChange}
                defaultValue={input.telefono}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            {true ? (
              <Button variant="primary" onClick={handleSave}>
                Save Changes
              </Button>
            ) : (
              ""
            )}
          </Modal.Footer>
        </Modal>
<Modal show={showCrear} onHide={handleCloseCrear}>
          <Modal.Header closeButton>
            <Modal.Title>Crear USUARIO</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <label>Email: . </label>
              <input
                type="text"
                name="email"
                onChange={handleInputChange}
                defaultValue={input.email}
              />
            </div>
            <div>
              <label>Apellido: .</label>
              <input
                type="text"
                name="apellido"
                onChange={handleInputChange}
                defaultValue={input.apellido}
              />
            </div>
            <div>
              <label>Nombre: .</label>
              <input
                type="text"
                name="nombre"
                onChange={handleInputChange}
                defaultValue={input.nombre}
              />
            </div>
            <div>
              <label>Telefono: .</label>
              <input
                type="text"
                name="telefono"
                onChange={handleInputChange}
                defaultValue={input.telefono}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseCrear}>
              Close
            </Button>
            {true ? (
              <Button variant="success" onClick={handleSaveCrear}>
                GUARDAR
              </Button>
            ) : (
              ""
            )}
          </Modal.Footer>
        </Modal>
<Modal show={showEliminar} onHide={()=> setShowEliminar(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Eliminar usuario</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <h1>Esta seguro que desea eliminar al suscriptor</h1>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={()=> setShowEliminar(false)}>
              Close
            </Button>
            {true ? (
              <Button variant="danger" onClick={handleSaveEliminar}>
                ELIMINAR
              </Button>
            ) : (
              ""
            )}
          </Modal.Footer>
        </Modal>

    </div>
  )
}
