import Axios from "axios";
import * as actionTypes from "../action-types";
import Sweal from 'sweetalert2'

Axios.defaults.baseURL = 'https://backbcdesarrollo-production.up.railway.app/api/'
// Axios.defaults.baseURL = "http://localhost:3001/api";

const proyectos1 = [
  {
    "name": "domoi",
    "title": "DOMO I",
    "bannerproyecto": "../../image/domo1/domo1logo.png",
    "tituloubicacion": "UBICACIÓN ESTRATÉGICA",
    "ubicacion": "Cerro Inacayal 65, Villa La Angostura, Neuquén",
    "map": "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1510.9800476790294!2d-71.6443309!3d-40.7629024!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9610bf429e37abb5%3A0xb64fd2698d5526b9!2sCerro%20Inacayal%2065%2C%20Villa%20La%20Angostura%2C%20Neuqu%C3%A9n!5e0!3m2!1ses-419!2sar!4v1675955138612!5m2!1ses-419!2sar",
    "titulo1": "MEMORIA DESCRIPTIVA",
    "foto1mob": "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689078956/dlq9vthr1lovfzpxho4q.jpg",
    "foto1desc": "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689078945/xer2wiqsjagn036uxemy.jpg",
    "foto2mob": "../../image/domo1/img08.jpg",
    "foto2desc": "../../image/domo1/img08.jpg",
    "texto1": "La construcción será del tipo tradicional con estructura de hormigón armado, losas premoldeadas y ladrillos cerámicos huecos, revoque grueso y terminación en revoque plástico en interiores y exteriores combinado con revestimiento cementicio símil piedra, aberturas de PVC con vidrios DVH, dando categoría y hermeticidad al edificio, calefacción por piso radiante en departamentos, oficinas y en locales comerciales, tanto el sistema de calefacción como la provisión de agua caliente sanitaria, será del tipo central.",
    "titulo2": "",
    "texto2": "Con un total de 26 unidades y 3 locales comerciales, el edificio contará con tres módulos de 3 niveles cada uno, en el subsuelo se dispondrán 20 cocheras. Además, un ascensor hidráulico de 3 paradas que conectará los demás niveles.",
    "youtube": "https://www.youtube.com/embed/ppHBbYVKOn4",
    "fotos": {
      "desktop": [
        "../../image/domo1/domodiurna.jpg",
        "../../image/domo1/domonieve.jpg",
        "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689078261/cp46bio4w9d0urnu9z3h.jpg",
        "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689078605/y8c5ja8sjomq9zpolstr.jpg",
        "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689078680/vpd8eeuw3pkups85uyzl.jpg",
        "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689078755/zaz2geconorbmd9cmjzt.jpg",
        "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689078789/qnjjc249tw2acbdbbzk6.jpg",
        "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689082309/wjttxjqjxpiamiwhyeox.jpg",
        "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689082314/p6xsadklewpenxzofwe1.jpg",
        "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689082334/qse6hxqv2ebmmfeynly4.jpg",
        "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689082340/u5d0ioxvwsh9n2onkwbw.jpg",
        "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689082550/q9cch64ofbykanyzimcw.jpg",
        "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689082561/au4s257lep5aizwrlms0.jpg"
      ],
      "mobile": [
        "../../image/domo1/domodiurna.jpg",
        "../../image/domo1/domonieve.jpg",
        "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689078806/x8pqqi6ben5ewtgy2j3t.jpg",
        "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689078828/yds5iu1ozrkwugom0rzl.jpg",
        "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689078840/pubgbehda688wudvjj3w.jpg",
        "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689078852/czotwx2pyfmvfuacfxbo.jpg",
        "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689078860/bodnsiy7zkyhcmaumfph.jpg",
        "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689078871/o91dtbykmeagh5evmmfn.jpg",
        "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689078878/agh90orlhxrq2iewigdy.jpg",
        "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689082417/ij9gc1biirrah2dpgbkr.jpg",
        "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689082438/efeor6szhqbkucj6y7zk.jpg",
        "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689082457/h2gh2ddc9vxnedmrkwx6.jpg",
        "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689082468/k4elqiq55eaeiv6eg2jb.jpg"
      ]
    },
    "textoiconos": [],
    "iconos": {residencia:true,oficina:true,comercial:true,mercado:false,poderadquisitivo:false,ubicacion:false,vacacional:true,profesional:true,cochera:false,cocherasubterranea:true,pavimento:true},
    "brochure": "https://drive.google.com/file/d/11Nr9hkZF4p1eHmBdett1FBTwVGbRK2Wc/view?usp=sharing",
    "createdAt": "2023-05-22 15:35:49.914+00",
    "updatedAt": "2023-07-11 13:36:42.709+00",
    "id": 5
  },
  {
    "name": "bentinicipolletti",
    "title": "BENTINI CIPOLLETTI",
    "bannerproyecto": "",
    "tituloubicacion": "DUPLEX A ESTRENAR",
    "ubicacion": "Ubicación: Carlos Loiseau 275, Arévalo, Cipolletti.",
    "map": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3104.2045069189394!2d-68.01413633307496!3d-38.91930293854018!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x960a31300677e96d%3A0x9ca1ae804f909d9e!2sCarlos%20Loiseau%2C%20Cipolletti%2C%20R%C3%ADo%20Negro!5e0!3m2!1ses-419!2sar!4v1680364960399!5m2!1ses-419!2sar",
    "titulo1": "",
    "foto1mob": "../../image/bentini/LATERAL1.jpeg",
    "foto1desc": "../../image/bentini/LATERAL1.jpeg",
    "foto2mob": "../../image/bentini/LATERAL2.jpeg",
    "foto2desc": "../../image/bentini/LATERAL2.jpeg",
    "texto1": "Los Duplex Bentini, se caracterizan por los detalles de calidad en cada terminación. Son una excelente oportunidad para invertir en barrio residencial de Cipolletti, Rio Negro. Dos unidades con similares características, módulos casi espejo, totalmente independientes.",
    "titulo2": "DETALLE",
    "texto2": "120 MTS2, 3 dormitorios, 1 en suite, 2 baños más. Living comedor, cocina, barra desayunadora, lavadero, garaje semi cubierto Calefacción central por losa radiante, aberturas de aluminio con DVH, muebles de cocina con melanina, mesada de granito, placares con interiores, vestidor.",
    "youtube": "",
    "fotos": {
      "desktop": [
        "../../image/bentini/1FOTO.jpeg",
        "../../image/bentini/FOTO1.jpeg",
        "../../image/bentini/FOTO2.jpeg",
        "../../image/bentini/FOTO3.jpeg",
        "../../image/bentini/FOTO5.jpg",
        "../../image/bentini/FOTO6.jpeg",
        "../../image/bentini/FOTO7.jpeg",
        "../../image/bentini/FOTO8.jpeg"
      ],
      "mobile": [
        "../../image/bentini/1FOTO.jpeg",
        "../../image/bentini/FOTO1.jpeg",
        "../../image/bentini/FOTO2.jpeg",
        "../../image/bentini/FOTO3.jpeg",
        "../../image/bentini/FOTO5.jpg",
        "../../image/bentini/FOTO6.jpeg",
        "../../image/bentini/FOTO7.jpeg",
        "../../image/bentini/FOTO8.jpeg"
      ]
    },
    "textoiconos": ["120 m2 totales", "98 m2 cubiertos", "4 ambientes", "2 baños", "Muy luminosos", "Zona residencial", "Apto Profesional", "Cochera"],
    "iconos": {residencia:false,oficina:false,comercial:false,mercado:false,poderadquisitivo:false,ubicacion:false,vacacional:false,profesional:false,cochera:false,cocherasubterranea:false,pavimento:false},
    "brochure": "",
    "createdAt": "2023-05-22 15:24:21.218+00",
    "updatedAt": "2023-06-04 18:35:51.687+00",
    "id": 6
  },
  {
    "name": "benhouse",
    "title": "BEN HOUSE",
    "bannerproyecto": "../../image/benhouse/logobenhouse.PNG",
    "tituloubicacion": "MINI CASA TRASLADABLE",
    "ubicacion": "NO CONTABILIZA F.O.S.",
    "map": "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d48354.90953089987!2d-71.637188!3d-40.758025!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9610bf3f4e4903b3%3A0x982baa6bb6528afd!2sBC%20Desarrollos!5e0!3m2!1ses-419!2sar!4v1686178526302!5m2!1ses-419!2sa",
    "titulo1": "TU CASA, TU SUEÑO, VINIENDO HACIA VOS",
    "foto1mob": "../../image/benhouse/ben7.JPG",
    "foto1desc": "../../image/benhouse/ben7.JPG",
    "foto2mob": "../../image/benhouse/ben6.JPG",
    "foto2desc": "../../image/benhouse/ben6.JPG",
    "texto1": "Benhouse es un nuevo concepto en Pequeñas Viviendas, amigables con el medio ambiente ya que nacen a partir de la estructura de buses fuera de servicio, evitando generar más chatarra que contamine nuestra tierra. Con un chasis ultra resistente, garantizan un traslado seguro, llegando al espacio deseado listas para disfrutar de su fortaleza y durabilidad eterna. La restauración en la estructura de las Benhouse, consiste en la aislación completa, instalación eléctrica, agua fría - caliente y desagües cloacales. Se entregan TOTALMENTE EQUIPADAS, listas para subir a disfrutarlas!",
    "titulo2": "DOS DISEÑOS DISPONIBLES:",
    "texto2": "FAMILY y SUITE: Modelos orientados tanto para residencia permanente como para turismo, se pueden generar mini complejos con una excelente rentabilidad en relación a su bajo costo de inversión y mantenimiento.",
    "youtube": "https://www.youtube.com/embed/XtoTXCe_T8I",
    "fotos": {
      "desktop": [
        "../../image/benhouse/ben8.JPG",
        "../../image/benhouse/ben2.JPG",
        "../../image/benhouse/ben3.JPG",
        "../../image/benhouse/ben4.JPG",
        "../../image/benhouse/ben5.JPG",
        "../../image/benhouse/ben6.JPG",
        "../../image/benhouse/ben7.JPG",
        "../../image/benhouse/ben1.JPG"
      ],
      "mobile": [
        "../../image/benhouse/ben8.JPG",
        "../../image/benhouse/ben2.JPG",
        "../../image/benhouse/ben3.JPG",
        "../../image/benhouse/ben4.JPG",
        "../../image/benhouse/ben5.JPG",
        "../../image/benhouse/ben6.JPG",
        "../../image/benhouse/ben7.JPG",
        "../../image/benhouse/ben1.JPG"
      ]
    },
    "textoiconos": [],
    "iconos": {residencia:false,oficina:false,comercial:false,mercado:false,poderadquisitivo:false,ubicacion:false,vacacional:true,profesional:true,cochera:false,cocherasubterranea:false,pavimento:false},
    "brochure": "https://drive.google.com/file/d/1_lTqnpOr1eTWJuPTtXE0ADCojLwqmQ1s/view$2usp=sharing",
    "createdAt": "2023-06-07 22:33:23.904+00",
    "updatedAt": "2023-06-07 22:55:57.682+00",
    "id": 11
  },
  {
    "name": "domoii",
    "title": "DOMO II",
    "bannerproyecto": "https://res.cloudinary.com/dkhdsoap8/image/upload/v1688654904/i9ghs7tltirgwhtklct7.jpg",
    "tituloubicacion": "EDIFICIO EN DESARROLLO",
    "ubicacion": "",
    "map": "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d48354.90953089987!2d-71.637188!3d-40.758025!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9610bf3f4e4903b3%3A0x982baa6bb6528afd!2sBC%20Desarrollos!5e0!3m2!1ses-419!2sar!4v1685462719233!5m2!1ses-419!2sar",
    "titulo1": "INTRODUCCIÓN",
    "foto1mob": "https://res.cloudinary.com/dkhdsoap8/image/upload/v1688657336/vpwzkpb4qveshduscsbo.jpg",
    "foto1desc": "https://res.cloudinary.com/dkhdsoap8/image/upload/v1688656697/thfzmgornmsml8rk8xyk.jpg",
    "foto2mob": "https://res.cloudinary.com/dkhdsoap8/image/upload/v1688657358/rcz5uwlugzjwz8daxkly.jpg",
    "foto2desc": "https://res.cloudinary.com/dkhdsoap8/image/upload/v1688657184/rxntu4vranlpxmm8jebr.jpg",
    "texto1": "DOMO II es un proyecto ubicado a tan solo 100 metros de la avenida principal de Villa La Angostura, sobre calle pavimentada. Contará con locales al frente en planta baja y departamentos de 2 y 3 ambientes, desde los 45 mts2. Todos emplazados en un edificio moderno de 3 módulos. Su estilo andino no es solo fachada, es un edificio resistente, diseñado con estructura antisísmica. Utilizamos materiales de primera calidad como ladrillos cerámicos, aberturas de PVC con vidrios DVH y revestimientos de piedra. Además, implementamos el sistema de calefacción por piso radiante y generación de energía alternativa.",
    "titulo2": "DETALLE",
    "texto2": "DOMO II contará con detalles interiores destacables, como revestimientos de porcelanato en pisos y baños, y sanitarios de primera línea. Además, la terminación de las paredes de interiores será con revoque de yeso y pintura al látex, brindando una categoría excepcional. Contará con estacionamiento subterráneo y un ascensor de 4 paradas, dando acceso a todo el complejo para facilitar la accesibilidada personas con capacidad reducida. 🌱 Comprometidos con el medio ambiente, DOMO II garantizará mediante un sistema innovador, el tratamiento y la re utilización de agua proveniente de duchas y lavabos para el riego de sus espacios verdes.",
    "youtube": "https://www.youtube.com/embed/sTuOAX6LhtE",
    "fotos": {
      "desktop": ["https://res.cloudinary.com/dkhdsoap8/image/upload/v1688654922/s9zsnwvtxc5fkipfdgie.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1688654929/njdqckx5gzcsgtzf9weu.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1688654951/elip0flhovpgeypmiy03.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1688654969/l877r8k45qg5e8sgmdbg.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1688654985/ahl1h10cgotoarcmsznb.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1688655007/tgt5ifom9ncx0dcofn0i.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1688655032/xwrqxwbsrmabasihb8dv.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1688655032/xwrqxwbsrmabasihb8dv.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1688655058/qod8bytgf5sk6naugbgy.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1688656697/thfzmgornmsml8rk8xyk.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1688656738/u2jutckop9mc4poawjry.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1688657039/kktwxrh59hgcupzepjmu.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1688657045/yrhnj5tjwwh68cuwqnxy.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1688657056/buao1bb6elk6vmtldcr7.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1688657086/pugiomy4ta0nh19nzr2u.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1688657119/tojtkqmiqmvx1rto5yqf.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1688657138/m0csfc79dgtbxrdpkuiz.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1688657159/gbbf9tmw1bradmnjejeo.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1688657184/rxntu4vranlpxmm8jebr.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1688657336/vpwzkpb4qveshduscsbo.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1688657358/rcz5uwlugzjwz8daxkly.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689078261/cp46bio4w9d0urnu9z3h.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689078605/y8c5ja8sjomq9zpolstr.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689078680/vpd8eeuw3pkups85uyzl.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689078680/vpd8eeuw3pkups85uyzl.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689078680/vpd8eeuw3pkups85uyzl.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689078731/v2eu6bv6htgbfr7wlcmy.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689078860/bodnsiy7zkyhcmaumfph.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689078878/agh90orlhxrq2iewigdy.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689078945/xer2wiqsjagn036uxemy.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689078945/xer2wiqsjagn036uxemy.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689081928/dfhrcaiprtlkbv0uwgof.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689081936/ht5ouwryncxifpiwlioo.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689081967/lxetiq8ogkfagpwdg9jp.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689082314/p6xsadklewpenxzofwe1.jpg"
      ],
      "mobile": ["https://res.cloudinary.com/dkhdsoap8/image/upload/v1688654922/s9zsnwvtxc5fkipfdgie.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1688654929/njdqckx5gzcsgtzf9weu.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1688654951/elip0flhovpgeypmiy03.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1688654969/l877r8k45qg5e8sgmdbg.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1688654985/ahl1h10cgotoarcmsznb.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1688655007/tgt5ifom9ncx0dcofn0i.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1688655032/xwrqxwbsrmabasihb8dv.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1688655032/xwrqxwbsrmabasihb8dv.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1688655058/qod8bytgf5sk6naugbgy.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1688656697/thfzmgornmsml8rk8xyk.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1688656738/u2jutckop9mc4poawjry.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1688657039/kktwxrh59hgcupzepjmu.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1688657045/yrhnj5tjwwh68cuwqnxy.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1688657056/buao1bb6elk6vmtldcr7.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1688657086/pugiomy4ta0nh19nzr2u.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1688657119/tojtkqmiqmvx1rto5yqf.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1688657138/m0csfc79dgtbxrdpkuiz.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1688657159/gbbf9tmw1bradmnjejeo.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1688657184/rxntu4vranlpxmm8jebr.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1688657336/vpwzkpb4qveshduscsbo.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1688657358/rcz5uwlugzjwz8daxkly.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689078261/cp46bio4w9d0urnu9z3h.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689078605/y8c5ja8sjomq9zpolstr.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689078680/vpd8eeuw3pkups85uyzl.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689078680/vpd8eeuw3pkups85uyzl.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689078680/vpd8eeuw3pkups85uyzl.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689078731/v2eu6bv6htgbfr7wlcmy.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689078860/bodnsiy7zkyhcmaumfph.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689078878/agh90orlhxrq2iewigdy.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689078945/xer2wiqsjagn036uxemy.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689078945/xer2wiqsjagn036uxemy.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689081928/dfhrcaiprtlkbv0uwgof.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689081936/ht5ouwryncxifpiwlioo.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689081967/lxetiq8ogkfagpwdg9jp.jpg",
      "https://res.cloudinary.com/dkhdsoap8/image/upload/v1689082314/p6xsadklewpenxzofwe1.jpg"
      ]
    },
    "textoiconos": [],
    "iconos": {residencia:true,oficina:false,comercial:true,mercado:false,poderadquisitivo:true,ubicacion:false,vacacional:false,profesional:true,cochera:false,cocherasubterranea:true,pavimento:true},
    "brochure": "https://drive.google.com/file/d/1il6G9UhN_J7c9ruLqYNN8bAVrdkc2Jz1/view?usp=drive_link",
    "createdAt": "2023-07-06 15:31:38.297+00",
    "updatedAt": "2023-07-19 21:07:29.469+00",
    "id": 12
  }
  
  ]


export const getProyectos = () => {


  // return async function (dispatch) {
  //   try {
  //     let info =  await Axios('getallproyectos');
  //     return dispatch({
  //       type: actionTypes.GET_PROYECTOS,
  //       payload: info.data,
  //     });
  //   } catch (error) {
  //     return "error";
  //   }
  // };
  return async function (dispatch) {
    try {
      return dispatch({
        type: actionTypes.GET_PROYECTOS,
        payload: proyectos1,
      });
    } catch (error) {
      return "error";
    }
  };
};
export const getProyectosById = (name) => {


  return async function (dispatch) {
    try {
      var proyectoId = {}

      for (let i = 0; i < proyectos1.length; i++) {
        if(proyectos1[i].name === name){
          proyectoId = proyectos1[i]
        }
        
      }
    // try {
    //   let info =  await Axios('getallproyectos');
    //   var proyectoId = {}

    //   for (let i = 0; i < info.data.length; i++) {
    //     if(info.data[i].name === name){
    //       proyectoId = info.data[i]
    //     }
        
    //   }

      


      return dispatch({
        type: actionTypes.GET_PROYECTOS_BY_ID,
        payload: proyectoId,
      });
    } catch (error) {
      return "error";
    }
  };
};


export const sendSuscripcion = (email) => {
  const suscriptor= {
    email:email,
    apellido: "SIN APELLIDO",
    nombre: "SIN NOMBRE",
    telefono: 1
  }


  return async function (dispatch) {
    try {

      // try {
      //   let info = await Axios.post(`http://localhost:3001/api/nuevosuscriptores`, suscriptor
      //   );
        
      //   ///////////////////////////////////////////////terminar/////////////////
      //   return dispatch({
      //     type: actionTypes.CREATE_SUSCRIPTORES,
      //     payload: info.data,
      //   });
      // } catch (error) {
      //   return "error";
      // }


      let info2 = await Axios.post(`nuevosuscriptores`, suscriptor)

      Sweal.fire('Éxito', 'Suscripto con exito', 'success')
      
      let info =  await Axios(
        {
          method: 'POST',
          url: 'https://formsubmit.co/ajax/administracion@bcsrl.com.ar',
          dataType: 'json',
          accepts: 'application/json',
          data: {
              name: "Suscription",
              message: email,
              subject: "SUSCRIPCION"
          },
          success: (data) => console.log(data),
          error: (err) => console.log(err)
      }
      );
      


      return dispatch({
        type: actionTypes.SEND_SUSCRIPCION,
        payload: info,
      });
    } catch (error) {
      return "error";
    }
  };
};


export const sendForm = (input,direccionmail) => {


  return async function (dispatch) {
    try {
      Sweal.fire('Éxito', 'Te contestaremos a la brevedad', 'success')
      
      let info =  await Axios(
        {
          method: 'POST',
          url: `https://formsubmit.co/ajax/${direccionmail}@bcsrl.com.ar`,
          dataType: 'json',
          accepts: 'application/json',
          data: {
              name: input.userName,
              message: input.message,
              subject: input.email
          },
          success: (data) => console.log(data),
          error: (err) => console.log(err)
      }
      );
      


      return dispatch({
        type: actionTypes.SEND_SUSCRIPCION,
        payload: info,
      });
    } catch (error) {
      return "error";
    }
  };
};
export const sendChatBot = (datos) => {

  console.log(datos)
  return async function (dispatch) {
    try {
      
      let info =  await Axios(
        {
          method: 'POST',
          url: `https://formsubmit.co/ajax/${datos.enviar}@bcsrl.com.ar`,
          dataType: 'json',
          accepts: 'application/json',
          data: {
              name: datos.nombre,
              message: datos.consulta,
              subject: datos.email
          },
          success: (data) => console.log(data),
          error: (err) => console.log(err)
      }
      );
      


      // return dispatch({
      //   type: actionTypes.SEND_SUSCRIPCION,
      //   payload: info,
      // });
    } catch (error) {
      return "error";
    }
  };
};


export const loginUser = (user) => {
  return async function (dispatch) {
    try {
      let info = await Axios.post(`login`, user);
      Sweal.fire('Éxito', 'Usuario y contraseña correcta', 'success')
        return dispatch({
          type: actionTypes.LOGIN,
          payload: info.data,
        });
        
    } catch (error) {
      Sweal.fire('Ups hubo un error', `${error.response.data.msg}`, 'error')
      return dispatch({
        type: actionTypes.LOGIN,
        payload: error,
      });
    }
  };
};
export const getUsers = () => {
  return async function (dispatch) {
    const token = window.localStorage.getItem("token")

    try {
      let info = await Axios.post(`suscriptores`, token, {
        headers : {Authorization : `Bearer ${token}`}
      }
      );
      return dispatch({
        type: actionTypes.GET_SUSCRIPTORES,
        payload: info.data,
      });
    } catch (error) {
      return "error";
    }
    
  };
};
export const createSuscriptor = (user) => {
  return async function (dispatch) {
    const token = window.localStorage.getItem("token")
    
    try {
      let info = await Axios.post(`crearsuscriptores`, user, {
        headers : {Authorization : `Bearer ${token}`}
      }
      );
      Sweal.fire({title: 'Éxito',
      icon: 'success',
      html: 'Se creo el suscriptor correctamente',
      confirmButtonText:
      '<i class="fa fa-thumbs-up"></i> OK!'
      // 'Éxito', 'Se creo el suscriptor correctamente', 'success', {confirmButtonText: '<i class="fa fa-thumbs-up"></i> Great!'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        window.location.reload();
      } else if (result.isDenied) {
        Sweal.fire('Changes are not saved', '', 'info')
      }
    })
      return dispatch({
        type: actionTypes.CREATE_SUSCRIPTORES,
        payload: info.data,
      });
    } catch (error) {
      return "error";
    }
    
  };
};
export const eliminarSuscriptor = (user) => {
  return async function (dispatch) {
    const token = window.localStorage.getItem("token")
    
    try {
      let info = await Axios.post(`eliminarsuscriptores`, user, {
        headers : {Authorization : `Bearer ${token}`}
      }
      );
      Sweal.fire({title: 'Éxito',
      icon: 'success',
      html: 'Se elimino correctamente',
      confirmButtonText:
      '<i class="fa fa-thumbs-up"></i> OK!'
      // 'Éxito', 'Se creo el suscriptor correctamente', 'success', {confirmButtonText: '<i class="fa fa-thumbs-up"></i> Great!'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        window.location.reload();
      } else if (result.isDenied) {
        Sweal.fire('Changes are not saved', '', 'info')
      }
    })
      return dispatch({
        type: actionTypes.CREATE_SUSCRIPTORES,
        payload: info.data,
      });
    } catch (error) {
      return "error";
    }
    
  };
};
export const actualizarSuscriptor = (user) => {
  return async function (dispatch) {
    const token = window.localStorage.getItem("token")
    
    try {
      let info = await Axios.post(`updatesuscriptores`, user, {
        headers : {Authorization : `Bearer ${token}`}
      }
      );
      Sweal.fire({title: 'Éxito',
      icon: 'success',
      html: 'Se actualizo correctamente',
      confirmButtonText:
      '<i class="fa fa-thumbs-up"></i> OK!'
      // 'Éxito', 'Se creo el suscriptor correctamente', 'success', {confirmButtonText: '<i class="fa fa-thumbs-up"></i> Great!'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        window.location.reload();
      } else if (result.isDenied) {
        Sweal.fire('Changes are not saved', '', 'info')
      }
    })
      return dispatch({
        type: actionTypes.CREATE_SUSCRIPTORES,
        payload: info.data,
      });
    } catch (error) {
      return "error";
    }
    
  };
};



export const getProyectos2 = () => {


  return async function (dispatch) {
    const token = window.localStorage.getItem("token")


    try {
      let info =  await Axios(`getallproyectos`);
      return dispatch({
        type: actionTypes.GET_PROYECTOS,
        payload: info.data,
      });
    } catch (error) {
      return "error";
    }
  };
};








export const eliminarProyecto = (user) => {
  return async function (dispatch) {
    const token = window.localStorage.getItem("token")
    
    try {
      let info = await Axios.post(`eliminarproyecto`, user, {
        headers : {Authorization : `Bearer ${token}`}
      }
      );
      Sweal.fire({title: 'Éxito',
      icon: 'success',
      html: 'Se elimino correctamente',
      confirmButtonText:
      '<i class="fa fa-thumbs-up"></i> OK!'
      // 'Éxito', 'Se creo el suscriptor correctamente', 'success', {confirmButtonText: '<i class="fa fa-thumbs-up"></i> Great!'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        window.location.reload();
      } else if (result.isDenied) {
        Sweal.fire('Changes are not saved', '', 'info')
      }
    })
      return dispatch({
        type: actionTypes.CREATE_SUSCRIPTORES,
        payload: info.data,
      });
    } catch (error) {
      return "error";
    }
    
  };
};
export const createProyecto = (proyecto) => {
  return async function (dispatch) {
    const token = window.localStorage.getItem("token")
    
    try {
      let info = await Axios.post(`createproyecto`, proyecto, {
        headers : {Authorization : `Bearer ${token}`}
      }
      );
      Sweal.fire({title: 'Éxito',
      icon: 'success',
      html: 'Se creo el Proyecto correctamente',
      confirmButtonText:
      '<i class="fa fa-thumbs-up"></i> OK!'
      // 'Éxito', 'Se creo el suscriptor correctamente', 'success', {confirmButtonText: '<i class="fa fa-thumbs-up"></i> Great!'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        window.location.reload();
      } else if (result.isDenied) {
        Sweal.fire('Changes are not saved', '', 'info')
      }
    })
      return dispatch({
        type: actionTypes.CREATE_PROYECTO,
        payload: info.data,
      });
    } catch (error) {
      return "error";
    }
    
  };
};

export const actualizarProyecto = (proyecto) => {
  return async function (dispatch) {
    const token = window.localStorage.getItem("token")
    
    try {
      let info = await Axios.post(`modificarproyecto`, proyecto, {
        headers : {Authorization : `Bearer ${token}`}
      }
      );
      Sweal.fire({title: 'Éxito',
      icon: 'success',
      html: 'Se actualizo correctamente',
      confirmButtonText:
      '<i class="fa fa-thumbs-up"></i> OK!'
      // 'Éxito', 'Se creo el suscriptor correctamente', 'success', {confirmButtonText: '<i class="fa fa-thumbs-up"></i> Great!'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        window.location.reload();
      } else if (result.isDenied) {
        Sweal.fire('Changes are not saved', '', 'info')
      }
    })
      return dispatch({
        type: actionTypes.CREATE_SUSCRIPTORES,
        payload: info.data,
      });
    } catch (error) {
      return "error";
    }
    
  };
};































// import axios from "axios";
// import * as actionTypes from "../action-types";
// import Sweal from 'sweetalert2'

// // Axios.defaults.baseURL = 'https://backsudestada-production.up.railway.app/api/'
// // Axios.defaults.baseURL = "http://localhost:3001/api";

// export const getProyectos = () => {


//   return async function (dispatch) {
//     try {
//       let info =  await axios('../../proyectos.json');
//       return dispatch({
//         type: actionTypes.GET_PROYECTOS,
//         payload: info.data,
//       });
//     } catch (error) {
//       return "error";
//     }
//   };
// };
// export const getProyectosById = (name) => {


//   return async function (dispatch) {
//     try {
//       let info =  await axios('../../proyectos.json');
//       var proyectoId = {}

//       for (let i = 0; i < info.data.length; i++) {
//         if(info.data[i].name === name){
//           proyectoId = info.data[i]
//         }
        
//       }

      


//       return dispatch({
//         type: actionTypes.GET_PROYECTOS_BY_ID,
//         payload: proyectoId,
//       });
//     } catch (error) {
//       return "error";
//     }
//   };
// };


// export const sendSuscripcion = (email) => {
//   const suscriptor= {
//     email:email,
//     apellido: "SIN APELLIDO",
//     nombre: "SIN NOMBRE",
//     telefono: 1
//   }


//   return async function (dispatch) {
//     try {

//       // try {
//       //   let info = await axios.post(`http://localhost:3001/api/nuevosuscriptores`, suscriptor
//       //   );
        
//       //   ///////////////////////////////////////////////terminar/////////////////
//       //   return dispatch({
//       //     type: actionTypes.CREATE_SUSCRIPTORES,
//       //     payload: info.data,
//       //   });
//       // } catch (error) {
//       //   return "error";
//       // }




//       Sweal.fire('Éxito', 'Suscripto con exito', 'success')
      
//       let info =  await axios(
//         {
//           method: 'POST',
//           url: 'https://formsubmit.co/ajax/administracion@bcsrl.com.ar',
//           dataType: 'json',
//           accepts: 'application/json',
//           data: {
//               name: "Suscription",
//               message: email,
//               subject: "SUSCRIPCION"
//           },
//           success: (data) => console.log(data),
//           error: (err) => console.log(err)
//       }
//       );
      


//       return dispatch({
//         type: actionTypes.SEND_SUSCRIPCION,
//         payload: info,
//       });
//     } catch (error) {
//       return "error";
//     }
//   };
// };


// export const sendForm = (input,direccionmail) => {


//   return async function (dispatch) {
//     try {
//       Sweal.fire('Éxito', 'Te contestaremos a la brevedad', 'success')
      
//       let info =  await axios(
//         {
//           method: 'POST',
//           url: `https://formsubmit.co/ajax/${direccionmail}@bcsrl.com.ar`,
//           dataType: 'json',
//           accepts: 'application/json',
//           data: {
//               name: input.userName,
//               message: input.message,
//               subject: input.email
//           },
//           success: (data) => console.log(data),
//           error: (err) => console.log(err)
//       }
//       );
      


//       return dispatch({
//         type: actionTypes.SEND_SUSCRIPCION,
//         payload: info,
//       });
//     } catch (error) {
//       return "error";
//     }
//   };
// };


// export const loginUser = (user) => {
//   return async function (dispatch) {
//     try {
//       let info = await axios.post(`http://localhost:3001/api/login`, user);
//       Sweal.fire('Éxito', 'Usuario y contraseña correcta', 'success')
//         return dispatch({
//           type: actionTypes.LOGIN,
//           payload: info.data,
//         });
        
//     } catch (error) {
//       Sweal.fire('Ups hubo un error', `${error.response.data.msg}`, 'error')
//       return dispatch({
//         type: actionTypes.LOGIN,
//         payload: error,
//       });
//     }
//   };
// };
// export const getUsers = () => {
//   return async function (dispatch) {
//     const token = window.localStorage.getItem("token")

//     try {
//       let info = await axios.post(`http://localhost:3001/api/suscriptores`, token, {
//         headers : {Authorization : `Bearer ${token}`}
//       }
//       );
//       return dispatch({
//         type: actionTypes.GET_SUSCRIPTORES,
//         payload: info.data,
//       });
//     } catch (error) {
//       return "error";
//     }
    
//   };
// };
// export const createSuscriptor = (user) => {
//   return async function (dispatch) {
//     const token = window.localStorage.getItem("token")
    
//     try {
//       let info = await axios.post(`http://localhost:3001/api/crearsuscriptores`, user, {
//         headers : {Authorization : `Bearer ${token}`}
//       }
//       );
//       Sweal.fire({title: 'Éxito',
//       icon: 'success',
//       html: 'Se creo el suscriptor correctamente',
//       confirmButtonText:
//       '<i class="fa fa-thumbs-up"></i> OK!'
//       // 'Éxito', 'Se creo el suscriptor correctamente', 'success', {confirmButtonText: '<i class="fa fa-thumbs-up"></i> Great!'
//     }).then((result) => {
//       /* Read more about isConfirmed, isDenied below */
//       if (result.isConfirmed) {
//         window.location.reload();
//       } else if (result.isDenied) {
//         Sweal.fire('Changes are not saved', '', 'info')
//       }
//     })
//       return dispatch({
//         type: actionTypes.CREATE_SUSCRIPTORES,
//         payload: info.data,
//       });
//     } catch (error) {
//       return "error";
//     }
    
//   };
// };
// export const eliminarSuscriptor = (user) => {
//   return async function (dispatch) {
//     const token = window.localStorage.getItem("token")
    
//     try {
//       let info = await axios.post(`http://localhost:3001/api/eliminarsuscriptores`, user, {
//         headers : {Authorization : `Bearer ${token}`}
//       }
//       );
//       Sweal.fire({title: 'Éxito',
//       icon: 'success',
//       html: 'Se elimino correctamente',
//       confirmButtonText:
//       '<i class="fa fa-thumbs-up"></i> OK!'
//       // 'Éxito', 'Se creo el suscriptor correctamente', 'success', {confirmButtonText: '<i class="fa fa-thumbs-up"></i> Great!'
//     }).then((result) => {
//       /* Read more about isConfirmed, isDenied below */
//       if (result.isConfirmed) {
//         window.location.reload();
//       } else if (result.isDenied) {
//         Sweal.fire('Changes are not saved', '', 'info')
//       }
//     })
//       return dispatch({
//         type: actionTypes.CREATE_SUSCRIPTORES,
//         payload: info.data,
//       });
//     } catch (error) {
//       return "error";
//     }
    
//   };
// };
// export const actualizarSuscriptor = (user) => {
//   return async function (dispatch) {
//     const token = window.localStorage.getItem("token")
    
//     try {
//       let info = await axios.post(`http://localhost:3001/api/updatesuscriptores`, user, {
//         headers : {Authorization : `Bearer ${token}`}
//       }
//       );
//       Sweal.fire({title: 'Éxito',
//       icon: 'success',
//       html: 'Se actualizo correctamente',
//       confirmButtonText:
//       '<i class="fa fa-thumbs-up"></i> OK!'
//       // 'Éxito', 'Se creo el suscriptor correctamente', 'success', {confirmButtonText: '<i class="fa fa-thumbs-up"></i> Great!'
//     }).then((result) => {
//       /* Read more about isConfirmed, isDenied below */
//       if (result.isConfirmed) {
//         window.location.reload();
//       } else if (result.isDenied) {
//         Sweal.fire('Changes are not saved', '', 'info')
//       }
//     })
//       return dispatch({
//         type: actionTypes.CREATE_SUSCRIPTORES,
//         payload: info.data,
//       });
//     } catch (error) {
//       return "error";
//     }
    
//   };
// };



// export const getProyectos2 = () => {


//   return async function (dispatch) {
//     const token = window.localStorage.getItem("token")


//     try {
//       let info =  await axios(`http://localhost:3001/api/getallproyectos`);
//       return dispatch({
//         type: actionTypes.GET_PROYECTOS,
//         payload: info.data,
//       });
//     } catch (error) {
//       return "error";
//     }
//   };
// };








// export const eliminarProyecto = (user) => {
//   return async function (dispatch) {
//     const token = window.localStorage.getItem("token")
    
//     try {
//       let info = await axios.post(`http://localhost:3001/api/eliminarproyecto`, user, {
//         headers : {Authorization : `Bearer ${token}`}
//       }
//       );
//       Sweal.fire({title: 'Éxito',
//       icon: 'success',
//       html: 'Se elimino correctamente',
//       confirmButtonText:
//       '<i class="fa fa-thumbs-up"></i> OK!'
//       // 'Éxito', 'Se creo el suscriptor correctamente', 'success', {confirmButtonText: '<i class="fa fa-thumbs-up"></i> Great!'
//     }).then((result) => {
//       /* Read more about isConfirmed, isDenied below */
//       if (result.isConfirmed) {
//         window.location.reload();
//       } else if (result.isDenied) {
//         Sweal.fire('Changes are not saved', '', 'info')
//       }
//     })
//       return dispatch({
//         type: actionTypes.CREATE_SUSCRIPTORES,
//         payload: info.data,
//       });
//     } catch (error) {
//       return "error";
//     }
    
//   };
// };
// export const createProyecto = (proyecto) => {
//   return async function (dispatch) {
//     const token = window.localStorage.getItem("token")
    
//     try {
//       let info = await axios.post(`http://localhost:3001/api/createproyecto`, proyecto, {
//         headers : {Authorization : `Bearer ${token}`}
//       }
//       );
//       Sweal.fire({title: 'Éxito',
//       icon: 'success',
//       html: 'Se creo el Proyecto correctamente',
//       confirmButtonText:
//       '<i class="fa fa-thumbs-up"></i> OK!'
//       // 'Éxito', 'Se creo el suscriptor correctamente', 'success', {confirmButtonText: '<i class="fa fa-thumbs-up"></i> Great!'
//     }).then((result) => {
//       /* Read more about isConfirmed, isDenied below */
//       if (result.isConfirmed) {
//         window.location.reload();
//       } else if (result.isDenied) {
//         Sweal.fire('Changes are not saved', '', 'info')
//       }
//     })
//       return dispatch({
//         type: actionTypes.CREATE_PROYECTO,
//         payload: info.data,
//       });
//     } catch (error) {
//       return "error";
//     }
    
//   };
// };

// export const actualizarProyecto = (proyecto) => {
//   return async function (dispatch) {
//     const token = window.localStorage.getItem("token")
    
//     try {
//       let info = await axios.post(`http://localhost:3001/api/modificarproyecto`, proyecto, {
//         headers : {Authorization : `Bearer ${token}`}
//       }
//       );
//       Sweal.fire({title: 'Éxito',
//       icon: 'success',
//       html: 'Se actualizo correctamente',
//       confirmButtonText:
//       '<i class="fa fa-thumbs-up"></i> OK!'
//       // 'Éxito', 'Se creo el suscriptor correctamente', 'success', {confirmButtonText: '<i class="fa fa-thumbs-up"></i> Great!'
//     }).then((result) => {
//       /* Read more about isConfirmed, isDenied below */
//       if (result.isConfirmed) {
//         window.location.reload();
//       } else if (result.isDenied) {
//         Sweal.fire('Changes are not saved', '', 'info')
//       }
//     })
//       return dispatch({
//         type: actionTypes.CREATE_SUSCRIPTORES,
//         payload: info.data,
//       });
//     } catch (error) {
//       return "error";
//     }
    
//   };
// };