export const GET_PROYECTOS = "GET_PROYECTOS";
export const GET_PROYECTOS_BY_ID = "GET_PROYECTOS_BY_ID";
export const SEND_SUSCRIPCION = "SEND_SUSCRIPCION";
export const GET_SUSCRIPTORES = "GET_SUSCRIPTORES";
export const CREATE_SUSCRIPTORES = "CREATE_SUSCRIPTORES";
export const CREATE_PROYECTO = "CREATE_PROYECTO";
export const MODIFICAR_PROYECTO = "MODIFICAR_PROYECTO";
export const LOGIN = "LOGIN";
export const CHATBOT_VENTAS = "CHATBOT_VENTAS";
export const CHATBOT_ADMINISTRACION = "CHATBOT_ADMINISTRACION";
export const CHATBOT_INFO = "CHATBOT_INFO";

