import React from "react";
import "./BotonesRedes.css";
import ReactGA from "react-ga4";
import { Chatbot } from "../chatboot/Chatbot";
import {Contenido} from "../chatboot/ChatbotResp";



export default function BotonesRedes() {


  const event = ()=>{
    ReactGA.event({
      category: "whatsapp",
      action: "whatsapp",
    });

  }




  return (
    <div className="fix_chatbot">
        {/* <a href="https://wa.me/5492944247437" target="_blank" rel="noopener noreferrer" className="btn-wsp" onClick={event}>
        <i className="bi bi-whatsapp"></i>
        </a> */}
      {/* <Chatbot /> */}
      <Contenido/>
    </div>

  );
}
