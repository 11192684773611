import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { eliminarProyecto } from '../../redux/actions';
import { Crearproyecto } from './Crearproyecto';
import { Modificarproyecto } from './Modificarproyecto';

export const Proyectosadmin = (info) => {

  const proyectos = info.proyectos.proyectos
  const rec = info.proyectos.rec

  const dispatch = useDispatch();


  const [show, setShow] = useState(1);
  const [showCrear, setShowCrear] = useState(false);
  const [showEliminar, setShowEliminar] = useState(false);

  useEffect(() => {
    if(show != 1){
      setShow(1)
    }
  }, [rec])
  
  

  const [input, setInput] = useState({
    id: "",
  });

  const userColumns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "title",
      headerName: "Nombre",
      width: 230,
    },
    {
      field: "createdAt",
      headerName: "Creado",
      width: 230,
    },
    {
      field: "updatedAt",
      headerName: "Modificado",
      width: 230,
    },      
    // {
    //   field: "status",
    //   headerName: "Status",
    //   width: 160,
    //   renderCell: (params) => {
    //     return (
    //       <div className={`cellWithStatus ${params.row.status}`}>
    //         {params.row.status}
    //       </div>
    //     );
    //   },
    // },
    {
      field: "action",
      headerName: "Action",
      width: 250,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            
            <button className="btn btn-primary"
                onClick={(x)=>modificarProyecto(params.row)}
              >
                MODIFICAR
              </button>
            <button
              className="btn btn-danger"
              style={{marginLeft: 10}}
              onClick={(x) => eliminarProyectos(params.row.id)}
            >
              Delete
            </button>
          </div>
        );
      },
    },
  ];


  const modificarProyecto = (e)=>{
    setInput(
      e
    )
    setShow(2)
  }
  const eliminarProyectos = (e)=>{
    setInput(
      {id: e}
    )
    setShowEliminar(true)
  }

  const handleSaveEliminar = ()=>{
    dispatch(eliminarProyecto(input))
    setTimeout(function(){
      setShowEliminar(false)
    }, 100);
  }



  return (

    show === 1 ?
    <div className="listContainer">
      <div className="datatable">
      <div className="datatableTitle "style={{padding: 20}}>
        <h1>PROYECTOS</h1>
        <button className='btn btn-success' onClick={()=> setShow(3)}>CREAR PROYECTO</button>
      </div>
      </div>
      <div style={{height: 700, padding: 20}}>      
      <DataGrid
    className="datagrid"
    columns={userColumns}
    rows={proyectos}
    pageSize={10}
    rowsPerPageOptions={[10]}
    slots={{ toolbar: GridToolbar }}
    // checkboxSelection
  /></div>
  <Modal show={showEliminar} onHide={()=> setShowEliminar(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Eliminar proyecto</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <h1>Esta seguro que desea eliminar el proyecto</h1>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={()=> setShowEliminar(false)}>
              Close
            </Button>
            {true ? (
              <Button variant="danger" onClick={handleSaveEliminar}>
                ELIMINAR
              </Button>
            ) : (
              ""
            )}
          </Modal.Footer>
        </Modal>
    </div>
    :
    show === 2 ?
    <div style={{padding: 20}}>
      <Modificarproyecto proyectoa={input}></Modificarproyecto>
    </div>
    :
    <div style={{padding: 20}}>
      <Crearproyecto></Crearproyecto>
    </div>
  )
}
