import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { loginUser } from '../../redux/actions'
import './login.css'

export const Login = () => {

    const dispatch = useDispatch()
    const navitage = useNavigate()

    const [input, setInput] = useState({username:"",password:""})
    const userLogin = useSelector((state) => state.UserLogin)


    const handleChange = (e)=>{
        
        setInput(
            {...input, [e.target.name]: e.target.value}
        )
    }


    const handleSubmit = (e)=>{
        e.preventDefault()
        dispatch(loginUser(input))
    }

 useEffect(() => {
   if(userLogin.usuario === 1){
    window.localStorage.setItem("token", userLogin.token)
    window.localStorage.setItem("usuario", userLogin.usuario)
    navitage("./paneladmin")
   }
 
 }, [userLogin])
 


  return (
    <div className='body__login'>
        <section className="body__section">
            <div className="form-box__login">
                <div className="form-value__login">
                    <form action="">
                        <h2 className='h2__login'>Login</h2>
                        <div className="inputbox__login">
                            <ion-icon name="mail-outline"></ion-icon>
                            <input onChange={handleChange} className='input__login' type="text" name='username' required/>
                            <label htmlFor="">Usuario</label>                        
                        </div>
                        <div className="inputbox__login">
                            <ion-icon name="lock-closed-outline"></ion-icon>
                            <input onChange={handleChange} className='input__login' type="password" name='password' required/>
                            <label htmlFor="">Contraseña</label>
                        </div>
                        <button onClick={handleSubmit} className='button__login'>Log in</button>
                    </form>
                </div>
            </div>
        </section>
    </div>
  )
}
