import React, { useEffect, useState } from "react";
import "./home.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Sliceshome } from "./sliceshome/Sliceshome";
import ReactGA from "react-ga4";

export const Home = () => {





  const [activo, setActivo] = useState(0)
  const [width, setWidth] = useState(window.innerWidth);
  const proyectos = useSelector((state) => state.Proyectos);
  const [tresproyectos, setTresproyectos] = useState([])



  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "home", title: "HOME" });
  }, [])
  

  useEffect(() => {
    if(proyectos.length > 0){
      const tres = []
      const proyectoId16 = proyectos.find(proyecto => proyecto.id === 16);
    const proyectoId15 = proyectos.find(proyecto => proyecto.id === 15);
    const proyectoId14 = proyectos.find(proyecto => proyecto.id === 14);
    const proyectoId13 = proyectos.find(proyecto => proyecto.id === 13);
    const proyectoId12 = proyectos.find(proyecto => proyecto.id === 12);
    const proyectoId5 = proyectos.find(proyecto => proyecto.id === 5);
    const proyectoId6 = proyectos.find(proyecto => proyecto.id === 6);
    const proyectoId11 = proyectos.find(proyecto => proyecto.id === 11);


    if (tres.length !== 3) {
      if (proyectoId16) {
        tres.push(proyectoId16);
        if (tres.length === 3) {
          setTresproyectos(tres);
        }
      }
    }
    
    if (tres.length !== 3) {
      if (proyectoId15) {
        tres.push(proyectoId15);
        if (tres.length === 3) {
          setTresproyectos(tres);
        }
      }
    }
    
    if (tres.length !== 3) {
      if (proyectoId14) {
        tres.push(proyectoId14);
        if (tres.length === 3) {
          setTresproyectos(tres);
        }
      }
    }
    
    if (tres.length !== 3) {
      if (proyectoId13) {
        tres.push(proyectoId13);
        if (tres.length === 3) {
          setTresproyectos(tres);
        }
      }
    }
    
    if (tres.length !== 3) {
      if (proyectoId12) {
        tres.push(proyectoId12);
        if (tres.length === 3) {
          setTresproyectos(tres);
        }
      }
    }
    
    if (tres.length !== 3) {
      if (proyectoId5) {
        tres.push(proyectoId5);
        if (tres.length === 3) {
          setTresproyectos(tres);
        }
      }
    }
    
    if (tres.length !== 3) {
      if (proyectoId6) {
        tres.push(proyectoId6);
        if (tres.length === 3) {
          setTresproyectos(tres);
        }
      }
    }
    
    if (tres.length !== 3) {
      if (proyectoId11) {
        tres.push(proyectoId11);
        if (tres.length === 3) {
          setTresproyectos(tres);
        }
      }
    }
  }
    // if(proyectos.length > 0){
    // setTresproyectos(solotres(proyectos,"id").slice(0,3))}
    
    
  }, [proyectos])
  
  
  const solotres = (array, key)=>{
      return array.sort(function(a, b) {
          var x = a[key]; var y = b[key];
          return ((y < x) ? -1 : ((y > x) ? 1 : 0));
      });
  }
  

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);
  

  const tiemposlice = () => {
    setTimeout(function(){
      if(activo === 0){
        setActivo(1)
      }else if(activo === 1){
        setActivo(2)
      }else{
        setActivo(0)
      }
  }, 4000)};

  useEffect(() => {
    if(width < 800){
      tiemposlice()

    }

  }, [activo, width])
  










  return (
    <main className="main__home">
      <section className="main__hero__home">
        <img src="../../image/home/fotolago.jpeg" className="imagen_home_hero" alt="" />
        

        {/* <Sliceshome></Sliceshome> */}



{/* 
        <div className="imagenes__slices__home">
          {activo === 0 ? 
            <img
              src="https://www.theagilityeffect.com/app/uploads/2019/03/00_VINCI-ICONOGRAPHIE-GettyImages-890132412_1280x680.jpg"
              alt=""
            />:
            activo === 1 ?
            <img
              src="https://media.cnn.com/api/v1/images/stellar/prod/140218103215-sheraton-huzhou-hot-spring-resort.jpg?q=w_1200,h_900,x_0,y_0,c_fill/w_1280"
              width="100%"
              alt=""
            />:
            <img
              src="https://cdn-3.expansion.mx/dims4/default/3558dbc/2147483647/strip/true/crop/1200x800+0+0/resize/1200x800!/format/webp/quality/90/?url=https%3A%2F%2Fcdn-3.expansion.mx%2F49%2F32%2F8f14e8dc4b659926ca959318781f%2Fedificios-siniestros-matthew-henry-15558-unsplash.jpg"
              width="100%"
              alt=""
            />

          }

        </div>
        {width > 800 ?
        <div className="botonera__slice__home">
          <button className={activo === 0 ? "button__slice__home button__slice__home__active":"button__slice__home"} onClick={()=>{setActivo(0)}}>1</button>
          <button className={activo === 1 ? "button__slice__home button__slice__home__active":"button__slice__home"} onClick={()=>{setActivo(1)}}>2</button>
          <button className={activo === 2 ? "button__slice__home button__slice__home__active":"button__slice__home"} onClick={()=>{setActivo(2)}}>3</button>
        </div>
        :""
        } */}


      </section>
      <section className="main__descrip__home container__home">
        <div className="main__descrip__home_g main__content__home">
          
          <div className="tituloyparrafohomebienvenido bienvenidohome">
            <h1 className="main__title__home titulos">BIENVENIDOS A <br></br>BC DESARROLLOS</h1>
            <p className="main__paragraph__home parrafos">
            Somos una empresa especializada en el desarrollo integral de todo tipo de proyectos inmobiliarios con sede en Villa la Angostura, Patagonia Argentina.
Ejecutamos y gestionamos obras y proyectos de arquitectura, ingeniería e interiores.<br></br>
Con más de 25 años de trayectoria, nuestro trabajo ha estado enfocado en reunir talentos de diferentes áreas para darle vida a nuevas ideas.

            </p>
          </div>
          <div className="fotobienvenidohome">
            <img
              src="../../image/domo1/domonieve.jpg"
              width="100%"
              alt=""
            />
          </div>

        </div>
      </section>
      <section className="main__about__home main__content__home">



      {/* <h2 className="main__about__home__h2">SOLIDEZ Y TRAYECTORIA</h2> */}
      <p className="p_titulos_home">SOLIDEZ Y TRAYECTORIA</p>
      <Link className="main__proyectos__link__home" to="/nosotros">
          Ver más
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="20">
              <g
                fill="none"
                fillRule="evenodd"
                stroke="var(--white)"
                strokeWidth="2"
              >
                <path d="M15 1l9 9-9 9M0 10h24"></path>
              </g>
            </svg>
          </span>
        </Link>

        {/* <h2 className="main__about__home__h2">SOLIDEZ Y TRAYECTORIA</h2>
        <Link className="main__about__link__home" to="/nosotros">
          Ver mas
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="20">
              <g
                fill="none"
                fillRule="evenodd"
                stroke="var(--white)"
                strokeWidth="2"
              >
                <path d="M15 1l9 9-9 9M0 10h24"></path>
              </g>
            </svg>
          </span>
        </Link> */}


      </section>
      <section className="main__proyectos__home main__content__home">
      <p className="p_titulos_home">PROYECTOS</p>
        {/* <h2 className="main__proyectos__home__h2">PROYECTOS</h2> */}

        {tresproyectos.length > 0 ?

        <>
        
        <div className="main__contenedor__proyectos__home" key={tresproyectos[1].id}>
          <Link to={`/proyectos/${tresproyectos[1].name}`}>
            <img
              className="imagen__proyecto__home"
              src={`${tresproyectos[1].fotos.mobile[0]}`}
              width="100%"
              alt=""
            />
            <h3 className="main__titulo__proyecto__home">{tresproyectos[1].title}</h3>
          </Link>
        </div>
        <div className="main__contenedor__proyectos__home" key={tresproyectos[2].id}>
          <Link to={`/proyectos/${tresproyectos[2].name}`}>
            <img
              className="imagen__proyecto__home"
              src={`${tresproyectos[2].fotos.mobile[0]}`}
              width="100%"
              alt=""
            />
            <h3 className="main__titulo__proyecto__home">{tresproyectos[2].title}</h3>
          </Link>
        </div>
        <div className="main__contenedor__proyectos__home" key={tresproyectos[0].id}>
          <Link to={`/proyectos/${tresproyectos[0].name}`}>
            <img
              className="imagen__proyecto__home"
              src={`${tresproyectos[0].fotos.mobile[0]}`}
              width="100%"
              alt=""
            />
            <h3 className="main__titulo__proyecto__home">{tresproyectos[0].title}</h3>
          </Link>
        </div>
        
        {/* tresproyectos.map(p =>
          <div className="main__contenedor__proyectos__home" key={p.id}>
          <Link to={`/proyectos/${p.name}`}>
            <img
              className="imagen__proyecto__home"
              src={`${p.fotos.mobile[0]}`}
              width="100%"
              alt=""
            />
            <h3 className="main__titulo__proyecto__home">{p.title}</h3>
          </Link>
        </div>
          
          ) */}
        </>
       :""
      }
{/* 
        <div className="main__contenedor__proyectos__home">
          <Link to="/proyectos/domo1">
            <img
              src="https://media.cnn.com/api/v1/images/stellar/prod/140218103215-sheraton-huzhou-hot-spring-resort.jpg?q=w_1200,h_900,x_0,y_0,c_fill/w_1280"
              width="100%"
              alt=""
            />
            <h3 className="main__titulo__proyecto__home">Domo1</h3>
          </Link>
        </div>
        <div className="main__contenedor__proyectos__home">
          <Link to="/proyectos/domo2">
            <img
              src="https://cdn-3.expansion.mx/dims4/default/3558dbc/2147483647/strip/true/crop/1200x800+0+0/resize/1200x800!/format/webp/quality/90/?url=https%3A%2F%2Fcdn-3.expansion.mx%2F49%2F32%2F8f14e8dc4b659926ca959318781f%2Fedificios-siniestros-matthew-henry-15558-unsplash.jpg"
              width="100%"
              alt=""
            />
            <h3 className="main__titulo__proyecto__home">Domo2</h3>
          </Link>
        </div>
        <div className="main__contenedor__proyectos__home">
          <Link to="/proyectos/domo3">
            <img
              src="https://resizer.glanacion.com/resizer/3YmGF8XQR6sllv3_aMB8W8CFTeQ=/768x0/filters:format(webp):quality(80)/cloudfront-us-east-1.images.arcpublishing.com/lanacionar/TI4ZHGFLEJCLPDVEST3UFTTQ6Q.jpg"
              width="100%"
              alt=""
            />
            <h3 className="main__titulo__proyecto__home">Domo3</h3>
          </Link>
        </div> */}

        <Link className="main__proyectos__link__home" to="/proyectos">
          Ver todos
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="20">
              <g
                fill="none"
                fillRule="evenodd"
                stroke="var(--white)"
                strokeWidth="2"
              >
                <path d="M15 1l9 9-9 9M0 10h24"></path>
              </g>
            </svg>
          </span>
        </Link>
      </section>
    </main>
  );
};
