import React, { useEffect, useState } from 'react'
import './slicesproyecto.css'
import ImageGallery from 'react-image-gallery';
import { useNavigate } from "react-router-dom";


export const Slicesproyecto = ({images}) => {
  

  const image = [
    {
      original: 'https://media.admagazine.com/photos/625465331fc7637dbef3f48d/3:4/w_2679,h_3571,c_limit/5.%20Photo%20by%20Dronalist.jpeg',
      originalTitle: 'domo1',
      // originalHeight: '300px',
      thumbnail: 'https://picsum.photos/id/1018/250/150/',
    },
    {
      original: 'https://picsum.photos/id/1015/1000/600/',
      thumbnail: 'https://picsum.photos/id/1015/250/150/',
      originalTitle: 'domo2',
    },
    {
      original: 'https://picsum.photos/id/1019/1000/600/',
      thumbnail: 'https://picsum.photos/id/1019/250/150/',
      originalTitle: 'domo3',
      description: <div className='description__slider'><p >Custom class for slides & thumbnails</p><br /><p>Custom class for slides & thumbnails</p></div>,
    },
    {
      original: 'https://media.admagazine.com/photos/625465331fc7637dbef3f48d/3:4/w_2679,h_3571,c_limit/5.%20Photo%20by%20Dronalist.jpeg',
      originalTitle: 'domo1',
      // originalHeight: '300px',
      thumbnail: 'https://picsum.photos/id/1018/250/150/',
    },
    {
      original: 'https://picsum.photos/id/1015/1000/600/',
      thumbnail: 'https://picsum.photos/id/1015/250/150/',
      originalTitle: 'domo2',
    },
    {
      original: 'https://picsum.photos/id/1019/1000/600/',
      thumbnail: 'https://picsum.photos/id/1019/250/150/',
      originalTitle: 'domo3',
      description: <div className='description__slider'><p >Custom class for slides & thumbnails</p><br /><p>Custom class for slides & thumbnails</p></div>,
    },
  ];

  // const enclick = (e)=>{
  
  //   navigate(`/proyectos/${e.target.title}`);
  // }


  //<div className='slice_proyecto_div'> </div>

  return (
    
    <div className='slice_proyecto_div'>
      <ImageGallery items={images} showFullscreenButton={false} showPlayButton={false} slideDuration={600} autoPlay={true} />
      
      </div>
    
  )
}
