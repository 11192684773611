import React, { useEffect, useRef, useState } from "react";
import "./chatbot.css";
import ReactGA from "react-ga4";
import { useDispatch } from "react-redux";
import { sendChatBot } from "../../redux/actions";

const EXAMPLES = [
  { text: "Donde llamo?", label: "Contacto" },
  { text: "cuanto sale?", label: "Valores" },
  { text: "cuantas habitaciones tiene?", label: "queProyecto" },
  { text: "domo 1", label: "domo1" },
  { text: "cundo se termina?", label: "Contacto" },
  { text: "porque sale tan caro?", label: "Valores" },
  { text: "cuales proyectos tienen?", label: "queProyecto" },
  { text: "cual es el ultimo proyecto?", label: "domo1" },
];
const API_KEY = "5sxKW5l03kdJ960nwHsdsiwm7HB1X1OEXmGGo9k9";



export const Contenido = () => {

    const dispatch = useDispatch()


    const [messages, setMessages] = useState([
        {
          id: "1",
          type: "bot",
          text: "Gracias por comunicarte con BC Desarrollos",
        },
        {
          id: "2",
          type: "bot",
          text: "Cuál es tu nombre?",
        },
        // {
        //   id: "2",
        //   type: "user",
        //   text: "Hola, soy un user",
        // },
      ]);
    
      const [question, setQuestion] = useState("");
      const [loading, setLoading] = useState(false)
      const container = useRef(null)
      const [isCollapsed, setIsCollapsed] = useState(true)
      const [numPreg, setNumPreg] = useState(0)
      const [nombre, setNombre] = useState("")
      const [email, setEmail] = useState("")
      const [primeras, setPrimeras] = useState(0)
      const [segundas, setSegundas] = useState(0)
      const [consulta, setConsulta] = useState("")
      const [final, setFinal] = useState(false)

      const ANSWERS = {
        default: (
            <p>
                Por favor elegi una opción correcta.
            </p>
        ),
        Contacto: (
            <p>
                Esta es la respuesta para contacto
            </p>
        ),
        1: (
            <div>
            <p>
                1 - DOMO II
            </p>
            <p>
                2 - VOLVER
            </p>

            </div>
        ),
        2: (
            <div>
            <p>
                1 - DOMO I
            </p>
            <p>
                2 - BENTINI CIPOLETTI
            </p>
            <p>
                3 - VOLVER
            </p>

            </div>
        ),
        3: (
            <div>
            <p>
                1 - BENHOUSE FAMILY
            </p>
            <p>
                2 - BENHOUSE SUITE
            </p>
            <p>
                3 - VOLVER
            </p>

            </div>
        ),
        4: (
            <div>
            <p>
                1 - ADMINISTRACIÓN
            </p>
            <p>
                2 - DOCUMENTACIÓN PENDIENTE
            </p>
            <p>
                3 - RECLAMOS
            </p>
            <p>
                4 - RECOMENTACIONES
            </p>
            <p>
                5 - VOLVER
            </p>

            </div>
        ),
        5: (
            <div>
            <p>
                Escribinos tu consulta:
            </p>

            </div>
        ),
        nombre: (
            <p>
                Me dirías tu what's app?sin 0 ni 15 ej: 2944247437
            </p>
        ),
        email: (
            <div>
                <p>
                Hola {nombre}
                </p>
                <p>
                Para brindarte una mejor atención, seleccioná el motivo de tu consulta:
                </p>
                <p>
                1 - DESARROLLOS EN POZO
                </p>
                <p>
                2 - PROPIEDADES EN VENTA
                </p>
                <p>
                3 - MINI CASA TRASLADABLE
                </p>
                <p>
                4 - ATENCIÓN AL CLIENTE
                </p>
                <p>
                5 - OTRAS CONSULTAS
                </p>
            </div>
        ),
        todo: (
            <div>
                <p>
                    Muchas Gracias! En breve un asesor se pondrá en contacto contigo!
                </p>
                <p>
                    Tiene alguna consulta mas?
                </p>
                <p>
                1 - SI
                </p>
                <p>
                2 - NO
                </p>
            </div>
                
        ),
        celular: (
                <p>
                    El celular no es valido. Por favor ingresa un numero valido.
                </p>
        ),
        despedida: (
                <p>
                    Gracias por comunicarte con BC Desarrollos! Estaremos disponible para responder tus consultas si volves más tarde!
                </p>
        ),
    }
      const handleSubmit = async (event) => {
        event.preventDefault();
    
        if(loading){
            return
        }
    
        setLoading(true)
    
        setMessages((messages) => messages.concat({id: String(Date.now()), type: "user", text: question}))
    
        setQuestion("")

        var numeroPregunta = numPreg + 1
        var nombreOemail = "nada"


        if(numeroPregunta === 1) {
            nombreOemail = "nombre"
            setMessages((messages) => messages.concat({id: String(Date.now()), type: "bot", text: ANSWERS["nombre"] || ANSWERS['default']}))
        }else if(numeroPregunta === 2){
            function validarCadena(cadena, longitudEsperada) {
                // Expresión regular para verificar si la cadena contiene solo números
                const regexNumeros = /^[0-9]+$/;
              
                // Verificar si la cadena tiene la longitud esperada y contiene solo números
                if (cadena.length === longitudEsperada && regexNumeros.test(cadena)) {
                  return true; // La cadena cumple con los requisitos
                } else {
                  return false; // La cadena no cumple con los requisitos
                }
              }
              
              // Ejemplo de uso
              const cadena = question;
              const longitudEsperada = 10;
              
              if (validarCadena(cadena, longitudEsperada)) {
                nombreOemail = "email"
                setMessages((messages) => messages.concat({id: String(Date.now()), type: "bot", text: ANSWERS["email"] || ANSWERS['default']}))
              } else {
                numeroPregunta = 1
                setMessages((messages) => messages.concat({id: String(Date.now()), type: "bot", text: ANSWERS["celular"] || ANSWERS['default']}))
              }
            
        }else if(numeroPregunta === 3){
            if(primeras === 0){

                if(question == 1 || question == 2 || question == 3 || question == 4 || question == 5){
                   
                    setMessages((messages) => messages.concat({id: String(Date.now()), type: "bot", text: ANSWERS[question] || ANSWERS['default']}))
                    setPrimeras(question)
                }else{
                    numeroPregunta = 2
                    setMessages((messages) => messages.concat({id: String(Date.now()), type: "bot", text: ANSWERS['default'] || ANSWERS['default']}))
                }
            }
        }else{
            if(primeras == 1 && final === false){
                if(question == 1){
                   
                    setMessages((messages) => messages.concat({id: String(Date.now()), type: "bot", text: ANSWERS["todo"] || ANSWERS['default']}))
                    setSegundas(question)
                    setFinal(true)
                }else if(question == 2){
                    numeroPregunta = 2
                    setPrimeras(0)
                    setMessages((messages) => messages.concat({id: String(Date.now()), type: "bot", text: ANSWERS["email"] || ANSWERS['default']}))
                    
                }else{
                    numeroPregunta = 3
                    
                    setMessages((messages) => messages.concat({id: String(Date.now()), type: "bot", text: ANSWERS['default'] || ANSWERS['default']}))
                }
            }else if(primeras == 2 && final === false){
                if(question == 1 || question == 2){
                   
                    setMessages((messages) => messages.concat({id: String(Date.now()), type: "bot", text: ANSWERS["todo"] || ANSWERS['default']}))
                    setSegundas(question)
                    setFinal(true)
                }else if(question == 3){
                    numeroPregunta = 2
                    setPrimeras(0)
                    setMessages((messages) => messages.concat({id: String(Date.now()), type: "bot", text: ANSWERS["email"] || ANSWERS['default']}))
                    
                }else{
                    numeroPregunta = 3
                    
                    setMessages((messages) => messages.concat({id: String(Date.now()), type: "bot", text: ANSWERS['default'] || ANSWERS['default']}))
                }
            }else if(primeras == 3 && final === false){
                if(question == 1 || question == 2){
                   
                    setMessages((messages) => messages.concat({id: String(Date.now()), type: "bot", text: ANSWERS["todo"] || ANSWERS['default']}))
                    setSegundas(question)
                    setFinal(true)
                }else if(question == 3){
                    numeroPregunta = 2
                    setPrimeras(0)
                    setMessages((messages) => messages.concat({id: String(Date.now()), type: "bot", text: ANSWERS["email"] || ANSWERS['default']}))
                    
                }else{
                    numeroPregunta = 3
                    
                    setMessages((messages) => messages.concat({id: String(Date.now()), type: "bot", text: ANSWERS['default'] || ANSWERS['default']}))
                }
            }else if(primeras == 4 && final === false){
                if(question == 1 || question == 2 || question == 3 || question == 4){
                   
                    setMessages((messages) => messages.concat({id: String(Date.now()), type: "bot", text: ANSWERS["todo"] || ANSWERS['default']}))
                    setSegundas(question)
                    setFinal(true)
                }else if(question == 5){
                    numeroPregunta = 2
                    setPrimeras(0)
                    setMessages((messages) => messages.concat({id: String(Date.now()), type: "bot", text: ANSWERS["email"] || ANSWERS['default']}))
                    
                }else{
                    numeroPregunta = 3
                    
                    setMessages((messages) => messages.concat({id: String(Date.now()), type: "bot", text: ANSWERS['default'] || ANSWERS['default']}))
                }
            }else if(primeras == 5 && final === false){
                   
                    setMessages((messages) => messages.concat({id: String(Date.now()), type: "bot", text: ANSWERS["todo"] || ANSWERS['default']}))
                    setSegundas(1)
                    setConsulta(question)
                    setFinal(true)

            }else if(final){
                if(question == 1){
                    numeroPregunta = 2
                    setPrimeras(0)
                    setMessages((messages) => messages.concat({id: String(Date.now()), type: "bot", text: ANSWERS["email"] || ANSWERS['default']}))
                    setFinal(false)
                }else{
                    numeroPregunta = 0
                    setPrimeras(0)
                    setSegundas(0)

                    setMessages((messages) => messages.concat({id: String(Date.now()), type: "bot", text: ANSWERS["despedida"] || ANSWERS['default']}))
                    setFinal(false)
                }
            }
        }
        if(nombreOemail === "nombre"){
            setNombre(question)
        }
        if(nombreOemail === "email"){
            setEmail(question)
        }

        
        setNumPreg(numeroPregunta)
        setLoading(false)
    
      };
    
    
      useEffect(() => {
        if(!isCollapsed){
        container.current.scrollTo(0, container.current.scrollHeight);}
      }, [messages])
    
    
      const event = ()=>{
        ReactGA.event({
          category: "whatsapp",
          action: "whatsapp",
        });
    
      }


      useEffect(() => {

        var datos = {
            nombre : nombre,
            email: email,
            consulta: consulta,
            enviar: ""
        }

        if(segundas !== 0 && consulta === ""){
            
            if(primeras == 1 || primeras == 2 || primeras == 3){
                datos.enviar = "ventas"
            }
            if(primeras == 4 && segundas == 1){
                datos.enviar = "administracion"
            }
            if(primeras == 4 && segundas == 2 && segundas == 3 && segundas == 4 ){
                datos.enviar = "info"
            }
            dispatch(sendChatBot(datos))
        }
        if(segundas !== 0 && consulta !== ""){
            
            if(primeras == 5 ){
                datos.enviar = "info"
            }
            dispatch(sendChatBot(datos))
        }
        
      }, [segundas, consulta])
      


    return (
        <div className="fix_chatbot">
          {isCollapsed ? 
          <>
            <div 
              className="button_colapse_chatbot"
              onClick={()=> setIsCollapsed(false)}
            >
                <div className="robotito">
                <img className="callcenter" src="../../image/redes/callcenter.png" alt="" />
            {/* <i className=""></i> */}
                </div>
            </div>
            <a href="https://wa.me/5492944247437" target="_blank" rel="noopener noreferrer" className="btn-wsp" onClick={event}>
            <i className="bi bi-whatsapp"></i>
            </a>
          </>
            :
            <div className="main_chatbot">
              <button
              onClick={()=> setIsCollapsed(true)}
              className="button_colapsetrue_chatbot"
              >
                X
                </button>
        <div className="divchatbot">
          <div ref={container} className="mensajes_chatbot">
            {messages.map((message) => (
              <div
                key={message.id}
                className={`mensaje_chatbot ${
                  message.type === "bot" ? "white" : "mensaje_chatbot_usuario"
                }`}
              >
                {message.text}
              </div>
            ))}
          </div>
          <form className="form_chatbot" onSubmit={handleSubmit}>
            <input
              type="text"
              className="input_chatbot"
              placeholder="***"
              name="question"
              value={question}
              onChange={(event)=>setQuestion(event.target.value)}
            />
    
            {!loading?
            <button type="submit" className="button_chatbot">
              Enviar
            </button>
            :""
            
        }
          </form>
        </div>
        </div>
          }
        </div>
        
      );

}

