import React, { useEffect, useState } from "react";
import Iframe from "react-iframe";
import { Slicesproyecto } from "../proyecto/slicesproyecto/Slicesproyecto";
import Swal from "sweetalert2";
import Axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { actualizarProyecto } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";

export const Modificarproyecto = ({ proyectoa }) => {
  const dispatch = useDispatch()
  const [proyecto, setProyecto] = useState(proyectoa);
  const [width, setWidth] = useState(window.innerWidth);
  const [images, setImages] = useState([]);
  const [showMob, setShowmob] = useState(false);
  const [showDesc, setShowDesc] = useState(false);
  const [imagenes, setImagenes] = useState([])

  useEffect(() => {
    if (proyecto.fotos) {
      cargImg();
    }
  }, [proyecto]);

  const cargImg = () => {
    var img = [];

    if (width > 750) {
      for (let i = 0; i < proyecto.fotos.desktop.length; i++) {
        var obj = {
          original: "",
          originalTitle: "",
          thumbnail: "",
        };
        obj.original = proyecto.fotos.desktop[i];
        obj.originalTitle = proyecto.title + [i];
        obj.thumbnail = proyecto.fotos.desktop[i];
        img.push(obj);
      }
    } else {
      for (let i = 0; i < proyecto.fotos.desktop.length; i++) {
        var obj = {
          original: "",
          originalTitle: "",
          thumbnail: "",
        };
        obj.original = proyecto.fotos.mobile[i];
        obj.originalTitle = proyecto.title + [i];
        obj.thumbnail = proyecto.fotos.mobile[i];
        img.push(obj);
      }
    }

    setImages(img);
  };

  const handleChange = (e) => {
    
    

    setProyecto({
      ...proyecto,
      [e.target.name]: e.target.value,
    });

    
  };
  useEffect(() => {
    const name = "name";
    const nombre = proyecto.title.toLowerCase().replace(/\s+/g, '')
    
    setProyecto({
      ...proyecto,
      [name]: nombre
    });
    
  }, [proyecto.title])
  
  

  const handleChangeCheckbox = (e) => {
    const icono = "iconos";
    var iconos = proyecto.iconos;
    iconos[e.target.name] = e.target.checked;
    setProyecto({
      ...proyecto,
      [icono]: iconos,
    });
  };











  const handleUploadImage = async (e) => {
    const files = e.target.files;
    const data = new FormData();

    data.append("file", files[0]);
    data.append("upload_preset", "gzwhi2xg"); //nombre de la carpeta de cloudinary
    // setLoading(true);

    try {
      // CLOUDINARY_URL=cloudinary://449916713616631:Kn2TprrvUJEyFMJMsjj44Ls-FKI@dv2uyjmw1
      let response = await Axios.post(
        "https://api.cloudinary.com/v1_1/dkhdsoap8/image/upload",
        data
        // {
        //   method: "POST",
        //   body: data,
        // }
      );
      Swal.fire("Éxito", "Foto cargada con exito", "success");
      response = response.data;
      setProyecto({
        ...proyecto,
        [e.target.name]: response.secure_url,
      });
    } catch (error) {
      Swal.fire("Error", "Hubo un error al cargar la imagen", "error");
    }
  };

  const imagenesDesc = ()=>{
    setImagenes(proyecto.fotos.desktop)
    setShowDesc(true)

  }
  const imagenesMob = ()=>{
    setImagenes(proyecto.fotos.mobile)
    setShowmob(true)

  }



  const handleUploadImageSlice = async (e)=>{
    const indice = e.target.name
    const ima = imagenes
    
    const files = e.target.files;
    const data = new FormData();

    data.append("file", files[0]);
    data.append("upload_preset", "gzwhi2xg");


    try {
      // CLOUDINARY_URL=cloudinary://449916713616631:Kn2TprrvUJEyFMJMsjj44Ls-FKI@dv2uyjmw1
      let response = await Axios.post(
        "https://api.cloudinary.com/v1_1/dkhdsoap8/image/upload",
        data
      );
      Swal.fire("Éxito", "Foto cargada con exito", "success");
      response = response.data;
      ima[indice] = response.secure_url
      if(e.target.id === "desktop"){
        const foto = "fotos.desktop"
        setProyecto({
          ...proyecto,
          [foto]: ima,
        });

      }else{
        const foto = "fotos.mobile"
        setProyecto({
          ...proyecto,
          [foto]: ima,
        });
      }
    } catch (error) {
      Swal.fire("Error", "Hubo un error al cargar la imagen", "error");
    }


    setImagenes(ima)
  }

  const eliminarunafotoslice = (e)=>{
    const indice = e.target.name
    

    if(e.target.id === "desktop"){
      const ima = imagenes.splice(indice, 1)
      const foto = "fotos.desktop"
      // meses.splice(2, 1);
      setProyecto({
        ...proyecto,
        [foto]: ima,
      });
      setImagenes(proyecto.fotos.desktop)

    }else{
      const ima = imagenes.splice(indice, 1)
      const foto = "fotos.mobile"
      // meses.splice(2, 1);
      setProyecto({
        ...proyecto,
        [foto]: ima,
      });
      setImagenes(proyecto.fotos.mobile)
    }
  }

const agregarunafotoslice = async (e)=>{
  var ima = imagenes
    
    const files = e.target.files;
    const data = new FormData();

    data.append("file", files[0]);
    data.append("upload_preset", "gzwhi2xg");


    try {
      // CLOUDINARY_URL=cloudinary://449916713616631:Kn2TprrvUJEyFMJMsjj44Ls-FKI@dv2uyjmw1
      let response = await Axios.post(
        "https://api.cloudinary.com/v1_1/dkhdsoap8/image/upload",
        data
      );
      Swal.fire("Éxito", "Foto cargada con exito", "success");
      response = response.data;
      const url = response.secure_url
      ima.push(url)
      if(e.target.id === "desktop"){
        const foto = "fotos.desktop"
        setProyecto({
          ...proyecto,
          [foto]: ima,
        });
        setImagenes(ima)

      }else{
        const foto = "fotos.mobile"
        setProyecto({
          ...proyecto,
          [foto]: ima,
        });
        setImagenes(ima)
      }
    } catch (error) {
      Swal.fire("Error", "Hubo un error al cargar la imagen", "error");
    }


    
}

const guardarProyecto = ()=>{
  dispatch(actualizarProyecto(proyecto))
}









const onclickTextoIconos = (e)=>{
  const indice = e.target.name
    
      const textoiconosE = proyecto.textoiconos
      const textoiconos = "textoiconos"
      // meses.splice(2, 1);
      textoiconosE.splice(indice, 1)
      setProyecto({
        ...proyecto,
        [textoiconos]: textoiconosE,
      });

}


const [textIconosN, setTextIconosN] = useState("")
const handleInputCHangeTi =(e)=>{
  setTextIconosN(e.target.value)

}

const agregarTextIcon = ()=>{
  const textos = proyecto.textoiconos
  const textoiconos = "textoiconos"

  textos.push(textIconosN)
  setProyecto({
    ...proyecto,
    [textoiconos]: textos,
  });
  
}



const cambioMapa = (e) =>{
  const regex = /src="(.*?)"/;
const match = regex.exec(e.target.value);
const textomapa = "map"
if(e.target.value === ""){

  setProyecto({
    ...proyecto,
    [textomapa]: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d48354.90953089987!2d-71.637188!3d-40.758025!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9610bf3f4e4903b3%3A0x982baa6bb6528afd!2sBC%20Desarrollos!5e0!3m2!1ses-419!2sar!4v1685457411922!5m2!1ses-419!2sar"
  });

}else{
  setProyecto({
    ...proyecto,
    [textomapa]: match[0].substring(5, match[0].length - 2),
  });
}


}

const cambioYoutube = (e)=>{
  const url = e.target.value;
const parts = url.split('/');
const videoId = parts.pop();

  
  const textoyoutube = "youtube"
if(e.target.value === ""){

  setProyecto({
    ...proyecto,
    [textoyoutube]: ""
  });

}else{
  setProyecto({
    ...proyecto,
    [textoyoutube]: "https://www.youtube.com/embed/" + videoId,
  });
}
}
const cambioBrochure = (e)=>{
  const url = e.target.value;
  
  const textobrochure = "brochure"
if(e.target.value === ""){

  setProyecto({
    ...proyecto,
    [textobrochure]: ""
  });

}else{
  setProyecto({
    ...proyecto,
    [textobrochure]: url,
  });
}
}
















  return (
    <div >
      {/* <div class="file-select" id="src-file1">
      <input
      className="btn btn-success boton_slice_admin"
                      accept="image/*"
                      type="file"
                      name='file'
                      onChange={handleUploadImage}
                    />

      </div> */}
      <input
      type="text"
              className="h1__proyecto titulos textarea__proyectoadm"
              name="title"
              onChange={handleChange}
              defaultValue={proyecto.title}
            />
      <div className="contenedor__proyecto ">
        <section className="seccion__banner__proyecto container__proyecto">
          <img
            className="banner__proyecto__adm"
            src={proyecto.bannerproyecto}
            alt=""
          />
          {/* <button className="btn btn-success boton_slice_admin">cambiar</button> */}
          <div className="file-select" id="src-file1">
      <input
      className="btn btn-success boton_slice_admin"
                      accept="image/*"
                      type="file"
                      name='bannerproyecto'
                      onChange={handleUploadImage}
                    />

      </div>
        </section>
        <section className="slices__proyecto ">
          <Slicesproyecto images={images}></Slicesproyecto>
          <button className="btn btn-success boton_slice_admin" onClick={imagenesDesc}>
            FOTOS DESK
          </button>
          <button className="btn btn-success boton_slice_admin" onClick={imagenesMob}>
            FOTOS MOB
          </button>
          
        </section>

        <section className="primertexto__proyecto">
          <div className="main__content__proyecto">
            <textarea
              className="h1__proyecto titulos textarea__proyectoadm"
              name="titulo1"
              onChange={handleChange}
              defaultValue={proyecto.titulo1}
            ></textarea>
            <textarea
              className="p__proyecto parrafos textarea__proyectoadm"
              name="texto1"
              onChange={handleChange}
              defaultValue={proyecto.texto1}
            ></textarea>
            {/* <h1 className="h1__proyecto titulos">{proyecto.titulo1}</h1> */}
            {/* <p className="p__proyecto parrafos">{proyecto.texto1}</p> */}
          </div>
        </section>

        <section className="primerafoto__proyecto">
          <div>
          <img src={proyecto.foto1desc} alt="" />
          <img src={proyecto.foto1mob} className="imagen_arriba" alt="" />

          </div>
          {/* <button className="btn btn-success boton_slice_admin">
            CAMBIAR FOTO
          </button> */}
          <div className="file-select" id="foto-1desc">
      <input
      className="btn btn-success boton_slice_admin"
                      accept="image/*"
                      type="file"
                      name='foto1desc'
                      onChange={handleUploadImage}
                    />

      </div>
      <div className="file-select" id="foto-1mob">
      <input
      className="btn btn-success boton_slice_admin"
                      accept="image/*"
                      type="file"
                      name='foto1mob'
                      onChange={handleUploadImage}
                    />

      </div>
        </section>

        <section className="segundotexto__proyecto">
          <textarea
            className="h1__proyecto titulos textarea__proyectoadm"
            name="titulo2"
            onChange={handleChange}
            defaultValue={proyecto.titulo2}
          ></textarea>
          <textarea
            className="p__proyecto parrafos textarea__proyectoadm"
            name="texto2"
            onChange={handleChange}
            defaultValue={proyecto.texto2}
          ></textarea>
          {/* <h2 className="h1__proyecto ">{proyecto.titulo2}</h2>
          <p className="p__proyecto ">{proyecto.texto2}</p> */}
        </section>

        <section className="segundafoto__proyecto">
          <div>
            <img src={proyecto.foto2desc} alt="" />
            <img src={proyecto.foto2mob} className="imagen_arriba" alt="" />
            {/* <button className="btn btn-success boton_slice_admin">
              CAMBIAR FOTO
            </button> */}            
          </div>
          <div className="file-select" id="foto-1desc">
      <input
      className="btn btn-success boton_slice_admin"
                      accept="image/*"
                      type="file"
                      name='foto2desc'
                      onChange={handleUploadImage}
                    />

      </div>
      <div className="file-select" id="foto-1mob">
      <input
      className="btn btn-success boton_slice_admin"
                      accept="image/*"
                      type="file"
                      name='foto2mob'
                      onChange={handleUploadImage}
                    />

      </div>
        </section>
        <section className="ubicacion__proyecto">
          <textarea
            className="h1__proyecto titulos textarea__proyectoadm"
            name="tituloubicacion"
            onChange={handleChange}
            defaultValue={proyecto.tituloubicacion}
          ></textarea>
          <textarea
            className="p__proyecto parrafos textarea__proyectoadm"
            name="ubicacion"
            onChange={handleChange}
            defaultValue={proyecto.ubicacion}
          ></textarea>
          <ol className="switches">
            <li>
              <input
                className="input_checkbox_adm"
                type="checkbox"
                id="1"
                name="residencia"
                onChange={handleChangeCheckbox}
                defaultChecked={proyecto.iconos.residencia}
              />
              <label className="label_checkbox_adm" htmlFor="1">
                <span>
                  ZONA RESIDENCIAL
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    fill="currentColor"
                    className="bi bi-buildings-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15 .5a.5.5 0 0 0-.724-.447l-8 4A.5.5 0 0 0 6 4.5v3.14L.342 9.526A.5.5 0 0 0 0 10v5.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V14h1v1.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5V.5ZM2 11h1v1H2v-1Zm2 0h1v1H4v-1Zm-1 2v1H2v-1h1Zm1 0h1v1H4v-1Zm9-10v1h-1V3h1ZM8 5h1v1H8V5Zm1 2v1H8V7h1ZM8 9h1v1H8V9Zm2 0h1v1h-1V9Zm-1 2v1H8v-1h1Zm1 0h1v1h-1v-1Zm3-2v1h-1V9h1Zm-1 2h1v1h-1v-1Zm-2-4h1v1h-1V7Zm3 0v1h-1V7h1Zm-2-2v1h-1V5h1Zm1 0h1v1h-1V5Z" />
                  </svg>
                </span>
                <span></span>
              </label>
            </li>
            <li>
              <input
                className="input_checkbox_adm"
                type="checkbox"
                id="2"
                name="oficina"
                onChange={handleChangeCheckbox}
                defaultChecked={proyecto.iconos.oficina}
              />
              <label className="label_checkbox_adm" htmlFor="2">
                <span>
                  OFICINAS
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    fill="currentColor"
                    className="bi bi-person-workspace"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4 16s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H4Zm4-5.95a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                    <path d="M2 1a2 2 0 0 0-2 2v9.5A1.5 1.5 0 0 0 1.5 14h.653a5.373 5.373 0 0 1 1.066-2H1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v9h-2.219c.554.654.89 1.373 1.066 2h.653a1.5 1.5 0 0 0 1.5-1.5V3a2 2 0 0 0-2-2H2Z" />
                  </svg>
                </span>
                <span></span>
              </label>
            </li>
            <li>
              <input
                className="input_checkbox_adm"
                type="checkbox"
                id="3"
                name="comercial"
                onChange={handleChangeCheckbox}
                defaultChecked={proyecto.iconos.comercial}
              />
              <label className="label_checkbox_adm" htmlFor="3">
                <span>
                  LOCALES COMERCIALES
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    fill="currentColor"
                    className="bi bi-shop"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.371 2.371 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976l2.61-3.045zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0zM1.5 8.5A.5.5 0 0 1 2 9v6h1v-5a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v5h6V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5zM4 15h3v-5H4v5zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-3zm3 0h-2v3h2v-3z" />
                  </svg>
                </span>
                <span></span>
              </label>
            </li>
            <li>
              <input
                className="input_checkbox_adm"
                type="checkbox"
                id="4"
                name="mercado"
                onChange={handleChangeCheckbox}
                defaultChecked={proyecto.iconos.mercado}
              />
              <label className="label_checkbox_adm" htmlFor="4">
                <span>
                  MERCADO
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    fill="currentColor"
                    className="bi bi-graph-up-arrow"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M0 0h1v15h15v1H0V0Zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5Z"
                    />
                  </svg>
                </span>
                <span></span>
              </label>
            </li>
            <li>
              <input
                className="input_checkbox_adm"
                type="checkbox"
                id="5"
                name="poderadquisitivo"
                onChange={handleChangeCheckbox}
                defaultChecked={proyecto.iconos.poderadquisitivo}
              />
              <label className="label_checkbox_adm" htmlFor="5">
                <span>
                  PODER ADQUISITIVO
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    fill="currentColor"
                    className="bi bi-piggy-bank-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.964 1.527c-2.977 0-5.571 1.704-6.32 4.125h-.55A1 1 0 0 0 .11 6.824l.254 1.46a1.5 1.5 0 0 0 1.478 1.243h.263c.3.513.688.978 1.145 1.382l-.729 2.477a.5.5 0 0 0 .48.641h2a.5.5 0 0 0 .471-.332l.482-1.351c.635.173 1.31.267 2.011.267.707 0 1.388-.095 2.028-.272l.543 1.372a.5.5 0 0 0 .465.316h2a.5.5 0 0 0 .478-.645l-.761-2.506C13.81 9.895 14.5 8.559 14.5 7.069c0-.145-.007-.29-.02-.431.261-.11.508-.266.705-.444.315.306.815.306.815-.417 0 .223-.5.223-.461-.026a.95.95 0 0 0 .09-.255.7.7 0 0 0-.202-.645.58.58 0 0 0-.707-.098.735.735 0 0 0-.375.562c-.024.243.082.48.32.654a2.112 2.112 0 0 1-.259.153c-.534-2.664-3.284-4.595-6.442-4.595Zm7.173 3.876a.565.565 0 0 1-.098.21.704.704 0 0 1-.044-.025c-.146-.09-.157-.175-.152-.223a.236.236 0 0 1 .117-.173c.049-.027.08-.021.113.012a.202.202 0 0 1 .064.199Zm-8.999-.65a.5.5 0 1 1-.276-.96A7.613 7.613 0 0 1 7.964 3.5c.763 0 1.497.11 2.18.315a.5.5 0 1 1-.287.958A6.602 6.602 0 0 0 7.964 4.5c-.64 0-1.255.09-1.826.254ZM5 6.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" />
                  </svg>
                </span>
                <span></span>
              </label>
            </li>
            <li>
              <input
                className="input_checkbox_adm"
                type="checkbox"
                id="6"
                name="ubicacion"
                onChange={handleChangeCheckbox}
                defaultChecked={proyecto.iconos.ubicacion}
              />
              <label className="label_checkbox_adm" htmlFor="6">
                <span>
                  UBICACION
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    fill="currentColor"
                    className="bi bi-geo-alt-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                  </svg>
                </span>
                <span></span>
              </label>
            </li>
            <li>
              <input
                className="input_checkbox_adm"
                type="checkbox"
                id="7"
                name="vacacional"
                onChange={handleChangeCheckbox}
                defaultChecked={proyecto.iconos.vacacional}
              />
              <label className="label_checkbox_adm" htmlFor="7">
                <span>
                  APTO TURISMO
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    fill="currentColor"
                    className="bi bi-globe-americas"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0ZM2.04 4.326c.325 1.329 2.532 2.54 3.717 3.19.48.263.793.434.743.484-.08.08-.162.158-.242.234-.416.396-.787.749-.758 1.266.035.634.618.824 1.214 1.017.577.188 1.168.38 1.286.983.082.417-.075.988-.22 1.52-.215.782-.406 1.48.22 1.48 1.5-.5 3.798-3.186 4-5 .138-1.243-2-2-3.5-2.5-.478-.16-.755.081-.99.284-.172.15-.322.279-.51.216-.445-.148-2.5-2-1.5-2.5.78-.39.952-.171 1.227.182.078.099.163.208.273.318.609.304.662-.132.723-.633.039-.322.081-.671.277-.867.434-.434 1.265-.791 2.028-1.12.712-.306 1.365-.587 1.579-.88A7 7 0 1 1 2.04 4.327Z" />
                  </svg>
                </span>
                <span></span>
              </label>
            </li>
            <li>
              <input
                className="input_checkbox_adm"
                type="checkbox"
                id="8"
                name="profesional"
                onChange={handleChangeCheckbox}
                defaultChecked={proyecto.iconos.profesional}
              />
              <label className="label_checkbox_adm" htmlFor="8">
                <span>
                  APTO PROFESIONAL
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    fill="currentColor"
                    className="bi bi-person-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                  </svg>
                </span>
                <span></span>
              </label>
            </li>
            <li>
              <input
                className="input_checkbox_adm"
                type="checkbox"
                id="9"
                name="cochera"
                onChange={handleChangeCheckbox}
                defaultChecked={proyecto.iconos.cochera}
              />
              <label className="label_checkbox_adm" htmlFor="9">
                <span>
                  COCHERA
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    fill="currentColor"
                    className="bi bi-car-front-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2.52 3.515A2.5 2.5 0 0 1 4.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679c.033.161.049.325.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 0 1 .049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.807.807 0 0 0 .381-.404l.792-1.848ZM3 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm10 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM6 8a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2H6ZM2.906 5.189a.51.51 0 0 0 .497.731c.91-.073 3.35-.17 4.597-.17 1.247 0 3.688.097 4.597.17a.51.51 0 0 0 .497-.731l-.956-1.913A.5.5 0 0 0 11.691 3H4.309a.5.5 0 0 0-.447.276L2.906 5.19Z" />
                  </svg>
                </span>
                <span></span>
              </label>
            </li>
            <li>
              <input
                className="input_checkbox_adm"
                type="checkbox"
                id="10"
                name="cocherasubterranea"
                onChange={handleChangeCheckbox}
                defaultChecked={proyecto.iconos.cocherasubterranea}
              />
              <label className="label_checkbox_adm" htmlFor="10">
                <span>
                  COCHERA SUBTERRANEA
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    fill="currentColor"
                    className="bi bi-car-front-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2.52 3.515A2.5 2.5 0 0 1 4.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679c.033.161.049.325.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 0 1 .049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.807.807 0 0 0 .381-.404l.792-1.848ZM3 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm10 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM6 8a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2H6ZM2.906 5.189a.51.51 0 0 0 .497.731c.91-.073 3.35-.17 4.597-.17 1.247 0 3.688.097 4.597.17a.51.51 0 0 0 .497-.731l-.956-1.913A.5.5 0 0 0 11.691 3H4.309a.5.5 0 0 0-.447.276L2.906 5.19Z" />
                  </svg>
                </span>
                <span></span>
              </label>
            </li>
            <li>
              <input
                className="input_checkbox_adm"
                type="checkbox"
                id="11"
                name="pavimento"
                onChange={handleChangeCheckbox}
                defaultChecked={proyecto.iconos.pavimento}
              />
              <label className="label_checkbox_adm" htmlFor="11">
                <span>
                  CALLE PAVIMENTADA
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    fill="currentColor"
                    className="bi bi-signpost-split-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7 16h2V6h5a1 1 0 0 0 .8-.4l.975-1.3a.5.5 0 0 0 0-.6L14.8 2.4A1 1 0 0 0 14 2H9v-.586a1 1 0 0 0-2 0V7H2a1 1 0 0 0-.8.4L.225 8.7a.5.5 0 0 0 0 .6l.975 1.3a1 1 0 0 0 .8.4h5v5z" />
                  </svg>
                </span>
                <span></span>
              </label>
            </li>
          </ol>

          {proyecto.textoiconos && proyecto.textoiconos.length > 0 ?
          proyecto.textoiconos.map((p,i)=>
          <ul className="parrafos">
          <li ><input type="text" defaultValue={p} /><button className="btn btn-danger" name={i} onClick={onclickTextoIconos}>x</button></li>
          
        </ul>
          )
          :""
        }
        <ul>
          <li>
            <input type="text" name="" id="" onChange={handleInputCHangeTi}/>
          </li>
        </ul>
        <button className="btn btn-info" onClick={agregarTextIcon}>Agregar</button>


        </section>
        
        <section className="map__proyecto">
          <Iframe
            src={proyecto.map}
            width="100%"
            height="450"
            style="border:0;"
            allowfullscreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></Iframe>
          <label htmlFor="">Mapa: </label>
          <input type="text" defaultValue={proyecto.map} onChange={cambioMapa}/>
        </section>
        <section className="youtube__proyecto">
          <Iframe
            width="100%"
            height="315"
            src={proyecto.youtube}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></Iframe>
          <label htmlFor="">Youtube: </label>
          <input type="text" defaultValue={proyecto.youtube} onChange={cambioYoutube}/>
        </section>

        <section className="seccion__botones__proyect">
          <button className="boton__proyecto">
            <a href={proyecto.brochure} target="_blank">
              BROCHURE
            </a>
          </button>
          <label htmlFor="">BROCHURE: </label>
          <input type="text" defaultValue={proyecto.brochure} onChange={cambioBrochure}/>
          {/* <button className="boton__proyecto" >COMPARTIR</button> */}
        </section>
      </div>
      <button className="guardar-proyecto" onClick={guardarProyecto}>GUARDAR</button>
      <Modal show={showDesc} onHide={()=> setShowDesc(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Cambiar fotos tamaño grande</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              {imagenes.length > 0?
              imagenes.map((img,i) =>
              <div key={i}>
                <img src={img} alt="" width="300px"/>
                <div className="file-select" id="foto-slices-cambiar">
      <input
      className="btn btn-success boton_slice_admin"
                      accept="image/*"
                      type="file"
                      name={i}
                      id="desktop"
                      onChange={handleUploadImageSlice}
                    />

      </div>
      <button className="btn btn-danger" onClick={eliminarunafotoslice} name={i} id="desktop">ELIMINAR</button>
                </div>
                )
                :""
            }
            <div className="file-select" id="agregarfoto">
      <input
      className="btn btn-success boton_slice_admin"
                      accept="image/*"
                      type="file"
                      name="nuevafoto"
                      id="desktop"
                      onChange={agregarunafotoslice}
                    />

      </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={()=> setShowDesc(false)}>
              Close
            </Button>
            {/* {true ? (
              <Button variant="danger" >
                ELIMINAR
              </Button>
            ) : (
              ""
            )} */}
          </Modal.Footer>
        </Modal>
      <Modal show={showMob} onHide={()=> setShowmob(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Cambiar fotos tamaño chico</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              {imagenes.length > 0?
              imagenes.map((img,i) =>
              <div key={i}>
                <img src={img} alt="" width="300px"/>
                <div className="file-select" id="foto-slices-cambiar">
      <input
      className="btn btn-success boton_slice_admin"
                      accept="image/*"
                      type="file"
                      name={i}
                      id="mobile"
                      onChange={handleUploadImageSlice}
                    />

      </div>
      <button className="btn btn-danger" onClick={eliminarunafotoslice} name={i} id="mobile">ELIMINAR</button>
                </div>
                )
                :""
            }
            <div className="file-select" id="agregarfoto">
      <input
      className="btn btn-success boton_slice_admin"
                      accept="image/*"
                      type="file"
                      name="nuevafoto"
                      id="mobile"
                      onChange={agregarunafotoslice}
                    />

      </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={()=> setShowDesc(false)}>
              Close
            </Button>
            {/* {true ? (
              <Button variant="danger" >
                ELIMINAR
              </Button>
            ) : (
              ""
            )} */}
          </Modal.Footer>
        </Modal>
    </div>
  );
};
