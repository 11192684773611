import React, { useEffect, useState } from 'react'
import './contacto.css'
import Iframe from 'react-iframe'
import { useDispatch } from 'react-redux';
import { sendForm } from '../../redux/actions';
import Sweal from 'sweetalert2'
import ReactGA from "react-ga4";

export default function Contacto() {

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "contacto", title: "CONTACTO" });
      }, [])
    



    const dispatch = useDispatch()




    const [botonEnviar, setBotonEnviar] = useState(false)
    const [input, setInput] = useState({})
    const [error, setError] = useState({userName:false,email:false,message:false})
    const [direccionmail, setDireccionmail] = useState("administracion")
  
  
    const handleinputchange = (e)=>{
        setInput({...input, [e.target.name]: e.target.value});
        setError({userName:true,email:true,message:true})
        
        if(input.userName){
            if(input.userName.length < 3){
                setError({...error, ["userName"]: true})
            }else{
                setError({...error, ["userName"]: false})
            }

        }
        var validEmail =  /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;

        if(input.email){
            
            if(!validEmail.test(input.email)){
                setError({...error, ["email"]: true})
            }else{
                setError({...error, ["email"]: false})
            }

        }

        if(input.message){
            if(input.message.length < 3){
                setError({...error, ["message"]: true})
            }else{
                setError({...error, ["message"]: false})
            }

        }




    //   setInputSus(e.target.value)
    //   var validEmail =  /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
    //   if( validEmail.test(e.target.value) ){
    //     setBotonEnviar(true)
    // }else{
    //   setBotonEnviar(false)
    // }
  
    }
    
  
    const handleEnviar = (event)=>{
        event.preventDefault();
        if(error.email || error.userName || error.message){
            Sweal.fire('Ups hubo un error', `Por favor verificar formulario`, 'error')
        }else{
            dispatch(sendForm(input,direccionmail))
        }
      
    }

















  return (
    <main className="main__contacto ">
        <section className="foto__text__contacto ">
            <div className="div__foto__contacto ">
                    <img className='foto__contacto' src="../../image/home/Bahía_brava_península.jpg" alt="" width="100%"/>
                
            </div>
            {/* <div className="texto__contacto__titulo">
                <div className="textos__contacto">

                    <h1 className="titulo__contacto__h1">Tell us about your project</h1>
                    <p className="parrafo__contacto">
                    We’d love to hear more about your project. Please, leave a message below or give us a call. We have two offices, one in Texas and one in Tennessee. If you find yourself nearby, come say hello!
                    </p>
                    
                </div>
            </div> */}
        </section>
        <section className="info__text__contacto">
            <h2 className='h2__contacto__detalle titulos'>DETALLE DE <br></br> CONTACTO</h2>
            <div className="oficina__contacto">
                <div className="texto__contacto">
                    <p className="mail__contacto__texto">Ventas: <a href='mailto:ventas@bcsrl.com.ar'>ventas@bcsrl.com.ar</a></p>
                    <p className="mail__contacto__texto">Inversiones: <a href='mailto:inversiones@bcsrl.com.ar'>inversiones@bcsrl.com.ar</a></p>
                    <p className="mail__contacto__texto">Administración: <a href='mailto:administracion@bcsrl.com.ar'>administracion@bcsrl.com.ar</a></p>
                    <p className="mail__contacto__texto">Dirección: Los Araucanos 427, Q8407 Villa La Angostura, Neuquén</p>
                    <p className="mail__contacto__texto">Teléfono: +5492944247437</p>
                </div>
{/*                 
                <button className="boton__ver__mapa__contacto">
                    <a href='https://goo.gl/maps/haC9Cd12pz6EbN7L9' target="_blank">
                    Ver en mapa <span className='span__boton__contacto__flecha'><svg xmlns="http://www.w3.org/2000/svg" width="26" height="20"><g fill="none" fillRule="evenodd" stroke="black" strokeWidth="2"><path d="M15 1l9 9-9 9M0 10h24"></path></g></svg></span>

                    </a>
                </button> */}

            </div>
            <div className="oficina__contacto">
                <div className="texto__contacto texto__contacto2 ">
                    <p className="oficina__contacto__t ">Redes sociales</p>
                    <a href='https://www.facebook.com/profile.php?id=100066489128961' target="_blank"><img src="./image/redes/icons8-facebook-circled-96.svg"></img></a>                   
                    <a href='https://www.instagram.com/bcdesarrollos' target="_blank"><img src="./image/redes/icons8-instagram-96.svg"></img></a>
                    {/* <a href='https://goo.gl/maps/haC9Cd12pz6EbN7L9' target="_blank"><img src="./image/redes/icons8-twitter-96.svg"></img>                   </a> */}
                    <a href='https://wa.me/5492944247437' target="_blank"><img src="./image/redes/icons8-whatsapp-96.svg"></img>                   </a>
                    {/* <a href='https://goo.gl/maps/haC9Cd12pz6EbN7L9' target="_blank"><img src="./image/redes/icons8-youtube-logo-96.svg"></img>  </a>   */}
                </div>
            </div>
        </section>
        <section className="mapa__contacto__section">            
            <Iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39504.11886326746!2d-71.68067031602044!3d-40.75768570105293!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9610bf3f4e4903b3%3A0x982baa6bb6528afd!2sBC%20Desarrollos!5e0!3m2!1ses-419!2sar!4v1675782437303!5m2!1ses-419!2sar" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></Iframe>

        </section>        
        
        <section className="form__contacto__section">
            <h2 className="h2__contacto__detalle h2__contacto__detalle2 titulos">
                PARA MÁS INFORMACIÓN, 
                DEJANOS TU MENSAJE.
            </h2>
            <form noValidate="" className='form__contacto__todo'>
                <div className="sc-efBctP gcivou">
                    <label htmlFor=":r0:-name" className="sc-hKMtZM sc-cTQhss hNoFDp">Nombre:</label>
                    <input id=":r0:-name" name="userName" type="text" placeholder="type name" required="" onChange={handleinputchange}/>
                    {error.userName ?
                    <p className='color__error'>Debe completar su nombre</p>
                    :""                  
                }
                </div>
                <div className="sc-efBctP gcivou">
                    <label htmlFor=":r0:-email" className="sc-hKMtZM sc-cTQhss hNoFDp">Email:</label>
                    <input id=":r0:-email" name="email" type="email" placeholder="type email" required="" onChange={handleinputchange}/>
                    {error.email ?
                    <p className='color__error'>Debe completar su email</p>
                    :""                  
                }
                </div>
                <div className="sc-efBctP gcivou"><label htmlFor=":r0:-message" className="sc-hKMtZM sc-cTQhss hNoFDp">Mensaje:</label>
                <textarea id=":r0:-message" rows="3" name="message" placeholder="type message" required="" onChange={handleinputchange}></textarea>
                {error.message ?
                    <p className='color__error'>Debe completar el mensaje</p>
                    :""                  
                }
                </div>
                <div className="sc-eivou">
                    <label htmlFor="administracion" className="sc-hKMtZM sc-cTQhss hNoFDp labelcontacto">Administración: </label>
                    <input type="radio" name='diremail' defaultChecked="True" onChange={()=>setDireccionmail("administracion")}/>
                    <label htmlFor="ventas" className="sc-hKMtZM sc-cTQhss hNoFDp labelcontacto">Ventas: </label>
                    <input type="radio" name='diremail' onChange={()=>setDireccionmail("ventas")}/>
                    <label htmlFor="inversiones" className="sc-hKMtZM sc-cTQhss hNoFDp labelcontacto">Inversiones: </label>
                    <input type="radio" name='diremail' onChange={()=>setDireccionmail("inversiones")}/>
                </div>
                <button className="sc-eCYdqJ sc-jOrMOR jWFJdE cughdu" onClick={handleEnviar}>
                    {/* <span className="sc-papXJ kLRABL">Submit</span> */}
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="20"><g fill="none" fillRule="evenodd" stroke="var(--white)" strokeWidth="2">
                    <path d="M15 1l9 9-9 9M0 10h24"></path></g></svg>
                </button>
            </form>
        </section>
    </main>
  )
}
