import './App.css';
import { Route, Routes } from "react-router-dom";
import { Navbar } from './component/navbar/Navbar';
import { Footbar } from './component/footbar/Footbar';
import { Home } from './component/home/Home';
import Contacto from './component/contacto/Contacto';
import BotonesRedes from './component/botonwhats/BotonesRedes';
import { Nosotros } from './component/nosotros/Nosotros';
import { Proyectos } from './component/proyectos/Proyectos';
import { Historia } from './component/historia/Historia';
import { Proyecto } from './component/proyecto/Proyecto';
import ScrollToTop from './component/scrolltotop/Scrolltotop';
import { Login } from './component/login/Login';
import { Adminpanel } from './component/adminpanel/Adminpanel';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { Paginaconstruccion } from './component/paginaconstruccion/Paginaconstruccion';
import './fonts/BankGothic-Regular.ttf'
import './fonts/Gelion-Black.ttf'
import './fonts/Gelion-Bold.ttf'
import './fonts/Gelion-Regular.ttf'
import ReactGA from "react-ga4";

function App() {

  const options = {
    testMode: true
  };
  ReactGA.initialize("G-Z9MRWMZD4C");

  const [identif, setIdentif] = useState(false)
  const { pathname } = useLocation();

  useEffect(() => {
    if(window.localStorage.getItem("usuario") === "1"){
      setIdentif(true)
    }
  }, [pathname]);
  
  
  

  


  return (
    <div className="App">
      <Navbar></Navbar>
      <ScrollToTop></ScrollToTop>
      <div className='margintoptodo'></div>
      <Routes>
        {/* <Route exact path="/" element={<Paginaconstruccion></Paginaconstruccion>}  */}
        <Route exact path="/" element={<Home></Home>} />
        <Route exact path="/login" element={<Login></Login>} />
        {identif ?
        <Route exact path="/login/paneladmin" element={<Adminpanel></Adminpanel>} />
        :""
      }
      <Route exact path="/contacto" element={<Contacto></Contacto>} />
        <Route exact path="/nosotros" element={<Nosotros></Nosotros>} />
        <Route exact path="/proyectos" element={<Proyectos></Proyectos>} />
        <Route exact path="/historia" element={<Historia></Historia>} />
        <Route exact path="/proyectos/:name" element={<Proyecto></Proyecto>} />
      </Routes>
        <Footbar></Footbar>
        <BotonesRedes></BotonesRedes>
      
    </div>
  );
}

export default App;
