import React, { useState } from 'react'
import './footbar.css'
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { sendSuscripcion } from '../../redux/actions';

export const Footbar = () => {
  const dispatch = useDispatch()




  const [show, setShow] = useState(false);
  const [botonEnviar, setBotonEnviar] = useState(false)
  const [inputSus, setInputSus] = useState("")

  const handleClose = () => {
    setShow(false)
  };

  const handleinputchange = (e)=>{
    setInputSus(e.target.value)
    var validEmail =  /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
    if( validEmail.test(e.target.value) ){
      setBotonEnviar(true)
  }else{
    setBotonEnviar(false)
  }

  }

  const handleEnviar = ()=>{
    dispatch(sendSuscripcion(inputSus))
    handleClose()
  }




  return (
    <footer className='footer__footer'>
      <div className='footer__container'>
        <div className='logo__footer__div'>
          <img className='logo__footer' src="../../logo.png" alt="" />
        </div>
        <div className='footer__list__link'>
          <ul className='footer__ul'>
            <li><Link className='links__li__footer' to="../../nosotros">Nosotros</Link></li>
            <li><Link className='links__li__footer' to="../../proyectos">Proyectos</Link></li>
            <li><Link className='links__li__footer' to="../../contacto">Contactanos</Link></li>
          </ul>
        </div>
        <div className='button__suscribite__footer' onClick={()=>setShow(true)}>Suscribite</div>
      </div>
      <Modal show={show} onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered>
          <Modal.Header >
            <Modal.Title>SUSCRIBITE</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div className="form-group">
            <label htmlFor="recipient-name" className="col-form-label">Email:</label>
            <input type="text" className="form-control" id="recipient-name" onChange={handleinputchange}/>
          </div>
          </Modal.Body>
          <Modal.Footer>
            {botonEnviar ? 
            <Button variant="success" onClick={handleEnviar}>
              ENVIAR
            </Button>
            :""
            
          }
            <Button variant="danger" onClick={handleClose}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
    </footer>
  )
}
