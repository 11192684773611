import React, { useEffect } from 'react'
import { Sliceshistoria } from './sliceshistoria/Sliceshistoria'
import './historia.css'
import ReactGA from "react-ga4";


export const Historia = () => {

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "historia", title: "HISTORIA" });
  }, [])



    const images1 = [
        {
          original: '../../image/historia/quienessomos/portada-min.jpg',
          originalTitle: 'portada',
          // originalHeight: '300px',
          thumbnail: '../../image/historia/quienessomos/portada-min.jpg',
        },
        {
          original: '../../image/historia/quienessomos/foto1-min.jpg',
          thumbnail: '../../image/historia/quienessomos/foto1-min.jpg',
          originalTitle: 'foto1',
        },
        {
          original: '../../image/historia/quienessomos/foto2-min.jpg',
          thumbnail: '../../image/historia/quienessomos/foto2-min.jpg',
          originalTitle: 'foto2',
        },
        {
          original: '../../image/historia/quienessomos/foto3-min.jpg',
          thumbnail: '../../image/historia/quienessomos/foto3-min.jpg',
          originalTitle: 'foto3',
        },
        {
          original: '../../image/historia/quienessomos/foto4-min.jpg',
          thumbnail: '../../image/historia/quienessomos/foto4-min.jpg',
          originalTitle: 'foto4',
        }
      ];
    const images2 = [
        {
          original: '../../image/historia/trayectoria/portada.jpg',
          originalTitle: 'portada',
          // originalHeight: '300px',
          thumbnail: '../../image/historia/trayectoria/portada.jpg',
        },
        {
          original: '../../image/historia/trayectoria/foto1-min.jpg',
          thumbnail: '../../image/historia/trayectoria/foto1-min.jpg',
          originalTitle: 'foto1',
        },
        {
          original: '../../image/historia/trayectoria/foto2-min.jpg',
          thumbnail: '../../image/historia/trayectoria/foto2-min.jpg',
          originalTitle: 'foto2',
        },
        {
          original: '../../image/historia/trayectoria/foto3-min.jpg',
          thumbnail: '../../image/historia/trayectoria/foto3-min.jpg',
          originalTitle: 'foto3',
        },
        {
          original: '../../image/historia/trayectoria/foto4-min.jpg',
          thumbnail: '../../image/historia/trayectoria/foto4-min.jpg',
          originalTitle: 'foto4',
        },
        {
          original: '../../image/historia/trayectoria/foto5-min.jpg',
          thumbnail: '../../image/historia/trayectoria/foto5-min.jpg',
          originalTitle: 'foto5',
        },
        {
          original: '../../image/historia/trayectoria/foto6-min.jpg',
          thumbnail: '../../image/historia/trayectoria/foto6-min.jpg',
          originalTitle: 'foto6',
        },
      ];
    const images3 = [
        {
          original: '../../image/historia/referencias/portada.jpg',
          originalTitle: 'portada',
          // originalHeight: '300px',
          thumbnail: '../../image/historia/referencias/portada.jpg',
        },
        {
          original: '../../image/historia/referencias/foto1-min.jpg',
          thumbnail: '../../image/historia/referencias/foto1-min.jpg',
          originalTitle: 'foto1',
        },
        {
          original: '../../image/historia/referencias/foto2-min.jpg',
          thumbnail: '../../image/historia/referencias/foto2-min.jpg',
          originalTitle: 'foto2',
        },
        {
          original: '../../image/historia/referencias/foto3-min.jpg',
          thumbnail: '../../image/historia/referencias/foto3-min.jpg',
          originalTitle: 'foto3',
        },
        {
          original: '../../image/historia/referencias/foto4-min.jpg',
          thumbnail: '../../image/historia/referencias/foto4-min.jpg',
          originalTitle: 'foto4',
        },
        {
          original: '../../image/historia/referencias/foto5-min.jpg',
          thumbnail: '../../image/historia/referencias/foto5-min.jpg',
          originalTitle: 'foto5',
        },
        {
          original: '../../image/historia/referencias/foto6-min.jpg',
          thumbnail: '../../image/historia/referencias/foto6-min.jpg',
          originalTitle: 'foto6',
        },
      ];
    const images4 = [
        {
          original: '../../image/historia/viviendas/portada.jpg',
          originalTitle: 'portada',
          // originalHeight: '300px',
          thumbnail: '../../image/historia/viviendas/portada.jpg',
        },
        {
          original: '../../image/historia/viviendas/foto1-min.jpg',
          thumbnail: '../../image/historia/viviendas/foto1-min.jpg',
          originalTitle: 'foto1',
        },
        {
          original: '../../image/historia/viviendas/foto2-min.jpg',
          thumbnail: '../../image/historia/viviendas/foto2-min.jpg',
          originalTitle: 'foto2',
        },
        {
          original: '../../image/historia/viviendas/foto3-min.jpg',
          thumbnail: '../../image/historia/viviendas/foto3-min.jpg',
          originalTitle: 'foto3',
        },
        {
          original: '../../image/historia/viviendas/foto4-min.jpg',
          thumbnail: '../../image/historia/viviendas/foto4-min.jpg',
          originalTitle: 'foto4',
        },
        {
          original: '../../image/historia/viviendas/foto5-min.jpg',
          thumbnail: '../../image/historia/viviendas/foto5-min.jpg',
          originalTitle: 'foto5',
        }
      ];
    const images5 = [
        {
          original: '../../image/historia/interiores/portada.jpg',
          originalTitle: 'portada',
          // originalHeight: '300px',
          thumbnail: '../../image/historia/interiores/portada.jpg',
        },
        {
          original: '../../image/historia/interiores/foto1-min.jpg',
          thumbnail: '../../image/historia/interiores/foto1-min.jpg',
          originalTitle: 'foto1',
        },
        {
          original: '../../image/historia/interiores/foto2-min.jpg',
          thumbnail: '../../image/historia/interiores/foto2-min.jpg',
          originalTitle: 'foto2',
        },
        {
          original: '../../image/historia/interiores/foto3-min.jpg',
          thumbnail: '../../image/historia/interiores/foto3-min.jpg',
          originalTitle: 'foto3',
        },
        {
          original: '../../image/historia/interiores/foto4-min.jpg',
          thumbnail: '../../image/historia/interiores/foto4-min.jpg',
          originalTitle: 'foto4',
        },
        {
          original: '../../image/historia/interiores/foto5-min.jpg',
          thumbnail: '../../image/historia/interiores/foto5-min.jpg',
          originalTitle: 'foto5',
        },
        {
          original: '../../image/historia/interiores/foto6-min.jpg',
          thumbnail: '../../image/historia/interiores/foto6-min.jpg',
          originalTitle: 'foto6',
        },
        {
          original: '../../image/historia/interiores/foto7-min.jpg',
          thumbnail: '../../image/historia/interiores/foto7-min.jpg',
          originalTitle: 'foto7',
        },
        {
          original: '../../image/historia/interiores/foto8-min.jpg',
          thumbnail: '../../image/historia/interiores/foto8-min.jpg',
          originalTitle: 'foto8',
        },
        {
          original: '../../image/historia/interiores/foto9-min.jpg',
          thumbnail: '../../image/historia/interiores/foto9-min.jpg',
          originalTitle: 'foto9',
        },
      ];
    const images6 = [
        {
          original: '../../image/historia/obraspublicas/portada-min.jpg',
          originalTitle: 'portada',
          // originalHeight: '300px',
          thumbnail: '../../image/historia/obraspublicas/portada-min.jpg',
        },
        {
          original: '../../image/historia/obraspublicas/arrayanes-min.jpg',
          thumbnail: '../../image/historia/obraspublicas/arrayanes-min.jpg',
          originalTitle: 'foto1',
          description: <div className='description__slider'><p >Dirección: Península de Quetrihué. Provincia de Neuquén.</p><br /><p>Comitente: Parque Nacionales.</p><br /><p>Obra en parque nacional con acceso lacustre y peatonal.</p></div>,
        },
        {
          original: '../../image/historia/obraspublicas/bpn-min.jpg',
          thumbnail: '../../image/historia/obraspublicas/bpn-min.jpg',
          originalTitle: 'foto2',
          description: <div className='description__slider'><p >Dirección: Barrio El Cruce. Villa La Angostura.</p></div>
        },
        {
          original: '../../image/historia/obraspublicas/centrodeintr-min.jpg',
          thumbnail: '../../image/historia/obraspublicas/centrodeintr-min.jpg',
          originalTitle: 'foto3',
        },
        {
          original: '../../image/historia/obraspublicas/muellepehuenia-min.jpg',
          thumbnail: '../../image/historia/obraspublicas/muellepehuenia-min.jpg',
          originalTitle: 'foto4',
        }
      ];
      const images7 = [
        {
          original: '../../image/historia/vision/foto6-min.jpg',
          thumbnail: '../../image/historia/vision/foto6-min.jpg',
          originalTitle: 'foto6',
        },
        {
          original: '../../image/historia/vision/foto1-min.jpg',
          thumbnail: '../../image/historia/vision/foto1-min.jpg',
          originalTitle: 'foto1',
        },
        {
          original: '../../image/historia/vision/foto2-min.jpg',
          thumbnail: '../../image/historia/vision/foto2-min.jpg',
          originalTitle: 'foto2',
        },
        {
          original: '../../image/historia/vision/foto3-min.jpg',
          thumbnail: '../../image/historia/vision/foto3-min.jpg',
          originalTitle: 'foto3',
        },
        {
          original: '../../image/historia/vision/foto4-min.jpg',
          thumbnail: '../../image/historia/vision/foto4-min.jpg',
          originalTitle: 'foto4',
        },
        {
          original: '../../image/historia/vision/foto5-min.jpg',
          thumbnail: '../../image/historia/vision/foto5-min.jpg',
          originalTitle: 'foto5',
        },
        {          
          original: '../../image/historia/vision/portada.jpg',
          originalTitle: 'portada',
          // originalHeight: '300px',
          thumbnail: '../../image/historia/vision/portada.jpg',
        }
      ];



  return (
    <main className='main__historia'>
        <section className="primera__seccion__historia">
            <Sliceshistoria images={images1}></Sliceshistoria>            
        </section>
        <section className="segunda__seccion__historia container__seccion__historia">
            <div className="main__content__historia">
                <h2 className="main__title__historia titulos">¿QUIÉNES SOMOS?</h2>
                <p className="main__paragraph__historia parrafos">Nuestra empresa, inicialmente como constructora, comienza a brindar servicios en el año 1997 en la localidad de Villa La Angostura, expandiéndose a nivel federal durante su trayectoria.</p>
            </div>
        </section>



        <section className="primera__seccion__historia1">
            <Sliceshistoria images={images2}></Sliceshistoria>            
        </section>
        <section className="segunda__seccion__historia1 container__seccion__historia">
            <div className="main__content__historia">
                <h2 className="main__title__historia titulos">TRAYECTORIA</h2>
                <p className="main__paragraph__historia parrafos">Siempre contamos con un grupo de profesionales y proveedores que nos acompañaron y se comprometieron en cada proyecto con mucha responsabilidad; y bajo nuestra supervisión hemos podido brindar a nuestros clientes, la totalidad de los servicios requeridos. </p>
            </div>
        </section>
        <section className="primera__seccion__historia2">
            <Sliceshistoria images={images3}></Sliceshistoria>            
        </section>
        <section className="segunda__seccion__historia2 container__seccion__historia">
            <div className="main__content__historia">
                <h2 className="main__title__historia titulos">REFERENCIAS DE PRIMERA LÍNEA</h2>
                <p className="main__paragraph__historia parrafos">Dentro de nuestro alcance estuvieron la construcción de viviendas unifamiliares y multifamiliares de primer nivel, hoteles y cabañas con distintos sistemas constructivos. Además de obras de infraestructura, movimientos y nivelación de suelos de todo tipo, obras viales, tendidos de servicios tales como gas, electricidad, agua potable, etc. </p>
            </div>
        </section>
        <section className="primera__seccion__historia3">
            <Sliceshistoria images={images4}></Sliceshistoria>            
        </section>
        <section className="segunda__seccion__historia3 container__seccion__historia">
            <div className="main__content__historia">
                <h2 className="main__title__historia titulos">VIVIENDAS UNIFAMILIARES</h2>
                <p className="main__paragraph__historia parrafos">Ejecutamos obras en barrios residenciales de categoría como Villa Correntoso, Puerto Manzano, la Villa, Dos Lagos, zona del Puerto y península de Quetrihué, entre otros.<br></br>
Viviendas familiares de 1 y 2 plantas.<br></br>
Refacciones y ampliaciones.<br></br>
Proyectos personalizados.
</p>
            </div>
        </section>
        <section className="primera__seccion__historia4">
            <Sliceshistoria images={images5}></Sliceshistoria>            
        </section>
        <section className="segunda__seccion__historia4 container__seccion__historia">
            <div className="main__content__historia">
                <h2 className="main__title__historia titulos">INTERIORES</h2>
                <p className="main__paragraph__historia parrafos">Los proyectos se materializan y la excelencia nos acompaña desde el inicio hasta la instancia final de la obra. El objetivo es brindar confort, calidad y estética en cada detalle.</p>
            </div>
        </section>
        <section className="primera__seccion__historia5">
            <Sliceshistoria images={images6}></Sliceshistoria>            
        </section>
        <section className="segunda__seccion__historia5 container__seccion__historia">
            <div className="main__content__historia">
                <h2 className="main__title__historia titulos">OBRAS PÚBLICAS </h2>
                <ul className="main__paragraph__historia parrafos">
                  <li>Sendero Quetrihué-Arrayanes</li>
                  <li>Banco Provincia del Neuquén</li>
                  <li>Planta de Tratamiento de residuos sólidos. Villa La Angostura.</li>
                  <li>Oficina de Turismo. Villa Traful.</li>
                  <li>Centro de Interpretación Ambiental. Villa Traful.</li>
                  <li>Paseo Costanero y Muelle turístico. Villa Pehuenia.</li>
                  <li>Oficina de Turismo Villa Traful</li>
                  <li>Entre otras.</li>
                </ul>
                <p className="main__paragraph__historia parrafos">

                </p>
            </div>
        </section>
        <section className="primera__seccion__historia6">
            <Sliceshistoria images={images7}></Sliceshistoria>            
        </section>
        <section className="segunda__seccion__historia6 container__seccion__historia">
            <div className="main__content__historia">
                <h2 className="main__title__historia titulos">VISIÓN</h2>
                <p className="main__paragraph__historia parrafos">Ser reconocidos por nuestra credibilidad, competitividad e innovación en la construcción y reformas inmobiliarias ha sido nuestro camino para seguir creciendo de manera permanente y autosustentable.</p>
            </div>
        </section>




    </main>


    // <main className='main__historia'>
    //     <section className="primera__seccion__historia">
    //         <Sliceshistoria></Sliceshistoria>            
    //     </section>
    //     <section className="segunda__seccion__historia container__seccion__historia">
    //         <div className="main__content__historia">
    //             <h2 className="main__title__historia titulos">Nuestra historia titulo 1</h2>
    //             <p className="main__paragraph__historia parrafos">Curabitur vel enim erat. Fusce auctor non dui in tristique. Sed sagittis rhoncus pulvinar. Aliquam cursus sollicitudin semper. Duis malesuada nulla a quam lacinia, vitae faucibus dui eleifend. Etiam tellus ex, aliquet sit amet efficitur ut, tincidunt a orci. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam varius tempor diam, id dignissim erat porta ac. Mauris ac vehicula nulla, sollicitudin consectetur risus. Praesent venenatis mollis turpis, vel tempor est. Etiam fermentum diam sed augue iaculis lacinia. Donec sed nisi elementum, cursus velit quis, tincidunt purus.</p>
    //         </div>
    //     </section>
    //     <section className="main__bg__historia">
    //         <img src="https://viapais.com.ar/resizer/WuOWBEWElF4VBtlFGMAF0EXm58g=/980x640/smart/filters:quality(75):format(webp)/cloudfront-us-east-1.images.arcpublishing.com/grupoclarin/5E3BTYJXMFHK3FSO7UC6KR5HOU.jpg" alt="" />
    //     </section>
    //     <section className="main__about__historia container__seccion__historia">
    //         <div className="main__content__historia">
    //             <h3 className="main__title__historia titulos">Historia titulo 2</h3>
    //             <p className="main__paragraph__historia parrafos">Curabitur vel enim erat. Fusce auctor non dui in tristique. Sed sagittis rhoncus pulvinar. Aliquam cursus sollicitudin semper. Duis malesuada nulla a quam lacinia, vitae faucibus dui eleifend. Etiam tellus ex, aliquet sit amet efficitur ut, tincidunt a orci. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam varius tempor diam, id dignissim erat porta ac. Mauris ac vehicula nulla, sollicitudin consectetur risus. Praesent venenatis mollis turpis, vel tempor est. Etiam fermentum diam sed augue iaculis lacinia. Donec sed nisi elementum, cursus velit quis, tincidunt purus.</p>
    //         </div>
    //     </section>
    //     <section className="main__bg__historia main__bg__historia--second">
    //         <img src="https://www.rionegro.com.ar/wp-content/uploads/2021/04/PSX_20210415_220333-1.jpg" alt="" />
    //     </section>
    // </main>





  )
}
