import React, { useEffect, useRef, useState } from "react";
import "./chatbot.css";
import ReactGA from "react-ga4";

const EXAMPLES = [
  { text: "Donde llamo?", label: "Contacto" },
  { text: "cuanto sale?", label: "Valores" },
  { text: "cuantas habitaciones tiene?", label: "queProyecto" },
  { text: "domo 1", label: "domo1" },
  { text: "cundo se termina?", label: "Contacto" },
  { text: "porque sale tan caro?", label: "Valores" },
  { text: "cuales proyectos tienen?", label: "queProyecto" },
  { text: "cual es el ultimo proyecto?", label: "domo1" },
];
const API_KEY = "5sxKW5l03kdJ960nwHsdsiwm7HB1X1OEXmGGo9k9";

const ANSWERS = {
    default: (
        <p>
            este es el mensaje de default si no hay resp
        </p>
    ),
    Contacto: (
        <p>
            Esta es la respuesta para contacto
        </p>
    ),
    Valores: (
        <p>
            Que te importa cuanto cuesta
        </p>
    ),
    domo1: (
        <p>
            Este seria domo 1
        </p>
    ),
    queProyecto: (
        <p>
            de que carajo mehablas?
        </p>
    ),
}

export const Chatbot = () => {
  const [messages, setMessages] = useState([
    {
      id: "1",
      type: "bot",
      text: "Hola, soy un bot",
    },
    {
      id: "2",
      type: "user",
      text: "Hola, soy un user",
    },
  ]);

  const [question, setQuestion] = useState("");
  const [loading, setLoading] = useState(false)
  const container = useRef(null)
  const [isCollapsed, setIsCollapsed] = useState(true)

  const handleSubmit = async (event) => {
    event.preventDefault();

    if(loading){
        return
    }

    setLoading(true)

    setMessages((messages) => messages.concat({id: String(Date.now()), type: "user", text: question}))

    setQuestion("")
    const {classifications}= await fetch("https://api.cohere.ai/v1/classify", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${API_KEY}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        model: "large",
        inputs: [question],
        examples: EXAMPLES,
      }),
    }).then(res => res.json());

    setMessages((messages) => messages.concat({id: String(Date.now()), type: "bot", text: ANSWERS[classifications[0].prediction] || ANSWERS['default']}))

    setLoading(false)

  };


  useEffect(() => {
    if(!isCollapsed){
    container.current.scrollTo(0, container.current.scrollHeight);}
  }, [messages])


  const event = ()=>{
    ReactGA.event({
      category: "whatsapp",
      action: "whatsapp",
    });

  }
  

  return (
    <div className="fix_chatbot">
      {isCollapsed ? 
      <>
        <button 
          className="button_colapse_chatbot"
          onClick={()=> setIsCollapsed(false)}
        >
        <i class="bi bi-robot"></i>
        </button>
        <a href="https://wa.me/5492944247437" target="_blank" rel="noopener noreferrer" className="btn-wsp" onClick={event}>
        <i className="bi bi-whatsapp"></i>
        </a>
      </>
        :
        <div className="main_chatbot">
          <button
          onClick={()=> setIsCollapsed(true)}
          className="button_colapsetrue_chatbot"
          >
            X
            </button>
    <div className="divchatbot">
      <div ref={container} className="mensajes_chatbot">
        {messages.map((message) => (
          <div
            key={message.id}
            className={`mensaje_chatbot ${
              message.type === "bot" ? "white" : "mensaje_chatbot_usuario"
            }`}
          >
            {message.text}
          </div>
        ))}
      </div>
      <form className="form_chatbot" onSubmit={handleSubmit}>
        <input
          type="text"
          className="input_chatbot"
          placeholder="quien sos?"
          name="question"
          value={question}
          onChange={(event)=>setQuestion(event.target.value)}
        />

        {!loading?
        <button type="submit" className="button_chatbot">
          Enviar
        </button>
        :""
        
    }
      </form>
    </div>
    </div>
      }
    </div>
    
  );

};
