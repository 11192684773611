import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Slicesproyectos } from "./slicesproyectos/Slicesproyectos";
import './proyectos.css'


export const Proyectos = () => {
  const proyecto = useSelector((state) => state.Proyectos);
  const [width, setWidth] = useState(window.innerWidth);
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (proyecto.length > 0 && proyecto[0].fotos) {
      cargImg();
    }
  }, [proyecto]);

  const cargImg = () => {
    var img = [];
    
    const proyectoId16 = proyecto.find(proyecto => proyecto.id === 16);
    const proyectoId15 = proyecto.find(proyecto => proyecto.id === 15);
    const proyectoId14 = proyecto.find(proyecto => proyecto.id === 14);
    const proyectoId13 = proyecto.find(proyecto => proyecto.id === 13);
    const proyectoId12 = proyecto.find(proyecto => proyecto.id === 12);
    const proyectoId5 = proyecto.find(proyecto => proyecto.id === 5);
    const proyectoId6 = proyecto.find(proyecto => proyecto.id === 6);
    const proyectoId11 = proyecto.find(proyecto => proyecto.id === 11);
    
      if (width > 750) {
        var obj = {
          original: "",
          originalTitle: "",
          thumbnail: "",
          description: "",
        };


        

        if (proyectoId16) {
          const obj = {
            original: proyectoId16.fotos.desktop[0],
            originalTitle: proyectoId16.name,
            thumbnail: proyectoId16.fotos.desktop[0],
            description: proyectoId16.title
          };
          img.push(obj);
        }
        
        if (proyectoId15) {
          const obj = {
            original: proyectoId15.fotos.desktop[0],
            originalTitle: proyectoId15.name,
            thumbnail: proyectoId15.fotos.desktop[0],
            description: proyectoId15.title
          };
          img.push(obj);
        }
        
        if (proyectoId14) {
          const obj = {
            original: proyectoId14.fotos.desktop[0],
            originalTitle: proyectoId14.name,
            thumbnail: proyectoId14.fotos.desktop[0],
            description: proyectoId14.title
          };
          img.push(obj);
        }
        
        if (proyectoId13) {
          const obj = {
            original: proyectoId13.fotos.desktop[0],
            originalTitle: proyectoId13.name,
            thumbnail: proyectoId13.fotos.desktop[0],
            description: proyectoId13.title
          };
          img.push(obj);
        }
        
        if (proyectoId12) {
          const obj = {
            original: proyectoId12.fotos.desktop[0],
            originalTitle: proyectoId12.name,
            thumbnail: proyectoId12.fotos.desktop[0],
            description: proyectoId12.title
          };
          img.push(obj);
        }
        
        if (proyectoId5) {
          const obj = {
            original: proyectoId5.fotos.desktop[0],
            originalTitle: proyectoId5.name,
            thumbnail: proyectoId5.fotos.desktop[0],
            description: proyectoId5.title
          };
          img.push(obj);
        }
        
        if (proyectoId6) {
          const obj = {
            original: proyectoId6.fotos.desktop[0],
            originalTitle: proyectoId6.name,
            thumbnail: proyectoId6.fotos.desktop[0],
            description: proyectoId6.title
          };
          img.push(obj);
        }
        
        if (proyectoId11) {
          const obj = {
            original: proyectoId11.fotos.desktop[0],
            originalTitle: proyectoId11.name,
            thumbnail: proyectoId11.fotos.desktop[0],
            description: proyectoId11.title
          };
          img.push(obj);
        }

      } else {
        if (width < 750) {
          var obj = {
            original: "",
            originalTitle: "",
            thumbnail: "",
            description: "",
          };
          if (proyectoId16) {
            const obj = {
              original: proyectoId16.fotos.mobile[0],
              originalTitle: proyectoId16.name,
              thumbnail: proyectoId16.fotos.mobile[0],
              description: proyectoId16.title
            };
            img.push(obj);
          }
          
          if (proyectoId15) {
            const obj = {
              original: proyectoId15.fotos.mobile[0],
              originalTitle: proyectoId15.name,
              thumbnail: proyectoId15.fotos.mobile[0],
              description: proyectoId15.title
            };
            img.push(obj);
          }
          
          if (proyectoId14) {
            const obj = {
              original: proyectoId14.fotos.mobile[0],
              originalTitle: proyectoId14.name,
              thumbnail: proyectoId14.fotos.mobile[0],
              description: proyectoId14.title
            };
            img.push(obj);
          }
          
          if (proyectoId13) {
            const obj = {
              original: proyectoId13.fotos.mobile[0],
              originalTitle: proyectoId13.name,
              thumbnail: proyectoId13.fotos.mobile[0],
              description: proyectoId13.title
            };
            img.push(obj);
          }
          
          if (proyectoId12) {
            const obj = {
              original: proyectoId12.fotos.mobile[0],
              originalTitle: proyectoId12.name,
              thumbnail: proyectoId12.fotos.mobile[0],
              description: proyectoId12.title
            };
            img.push(obj);
          }
          
          if (proyectoId5) {
            const obj = {
              original: proyectoId5.fotos.mobile[0],
              originalTitle: proyectoId5.name,
              thumbnail: proyectoId5.fotos.mobile[0],
              description: proyectoId5.title
            };
            img.push(obj);
          }
          
          if (proyectoId6) {
            const obj = {
              original: proyectoId6.fotos.mobile[0],
              originalTitle: proyectoId6.name,
              thumbnail: proyectoId6.fotos.mobile[0],
              description: proyectoId6.title
            };
            img.push(obj);
          }
          
          if (proyectoId11) {
            const obj = {
              original: proyectoId11.fotos.mobile[0],
              originalTitle: proyectoId11.name,
              thumbnail: proyectoId11.fotos.mobile[0],
              description: proyectoId11.title
            };
            img.push(obj);
          }
        }
      }


    setImages(img);




    // for (let x = 0; x < proyecto.length; x++) {
    //   if (width > 750) {
    //     var obj = {
    //       original: "",
    //       originalTitle: "",
    //       thumbnail: "",
    //       description: "",
    //     };
    //     obj.original = proyecto[x].fotos.desktop[0];
    //     obj.originalTitle = proyecto[x].name;
    //     obj.thumbnail = proyecto[x].fotos.desktop[0];
    //     obj.description =  proyecto[x].title;

    //     img.push(obj);
    //   } else {
    //     if (width < 750) {
    //       var obj = {
    //         original: "",
    //         originalTitle: "",
    //         thumbnail: "",
    //         description: "",
    //       };
    //       obj.original = proyecto[x].fotos.mobile[0];
    //       obj.originalTitle = proyecto[x].name;
    //       obj.thumbnail = proyecto[x].fotos.mobile[0];
    //       obj.description = proyecto[x].title;

    //       img.push(obj);
    //     }
    //   }
    // }
    // setImages(img.reverse());
  };


  

  return (
    <>
    
    <main className="main__proyecto__proyecto">
      <section className="primera__seccion__proyecto__proyecto">
        <Slicesproyectos images={images}></Slicesproyectos>
      </section>
      <section className="segunda__seccion__proyecto__proyecto container__seccion__proyecto__proyecto">
        <div className="main__content__proyecto__proyecto">
          <h2 className="main__title__proyecto__proyecto titulos">
            PROYECTOS NUEVOS
          </h2>
          <p className="main__paragraph__proyecto__proyecto parrafos">
          Cada trabajo que realizamos genera un vínculo de confianza con nuestros clientes. Entendemos sus necesidades y brindamos soluciones sin imprevistos ni dolores de cabeza. 
          </p>
          <ul>
            <li>ACABADOS IMPECABLES</li>
            <li>TRANSPARENCIA CON EL CLIENTE</li>
            <li>PROYECTOS PERSONALIZADOS</li>
            <li>SUSTENTABILIDAD</li>
          </ul>
        </div>
      </section>


      
      {/* <section className="main__bg__proyecto__proyecto">
        <img
          src="https://viapais.com.ar/resizer/WuOWBEWElF4VBtlFGMAF0EXm58g=/980x640/smart/filters:quality(75):format(webp)/cloudfront-us-east-1.images.arcpublishing.com/grupoclarin/5E3BTYJXMFHK3FSO7UC6KR5HOU.jpg"
          alt=""
        />
      </section>



      <section className="main__about__proyecto__proyecto container__seccion__proyecto__proyecto">
        <div className="main__content__proyecto__proyecto">
          <h3 className="main__title__proyecto__proyecto titulos">
            Segundo titulo de proyectos
          </h3>
          <p className="main__paragraph__proyecto__proyecto parrafos">
          SUSTENTABILIDAD
Nuestro compromiso es utilizar técnicas modernas que además protegen el medio ambiente.<br></br>

TRANSPARENCIA 
Desarrollamos una constante interacción con nuestros clientes e inversores a través de muestreos regulares del avance de proyecto haciendo foco en el cumplimiento de los plazos establecidos.<br></br>

PRECIO JUSTO
Garantizamos un cuidadoso proceso de selección de proveedores de servicios y profesionales idóneos que aseguran la calidad en cada detalle.<br></br>

SATISFACCIÓN
La sumatoria de calidad, respeto, seguridad y confianza en nuestro servicio asegura la satisfacción de nuestros clientes al culminar cada proyecto.

          </p>
        </div>
      </section>
      <section className="main__bg__proyecto__proyecto main__bg__proyecto__proyecto--second">
        <img
          src="https://www.rionegro.com.ar/wp-content/uploads/2021/04/PSX_20210415_220333-1.jpg"
          alt=""
        />
      </section> */}



      
    </main>
    
      <div className="body__proyecto__proyecto">
      <div className="page-content__proyecto">
        <div className="card__proyecto">
          <div className="content__proyecto">
            <h2 className="title__proyecto">SUSTENTABILIDAD</h2>
            <p className="copy__proyecto">Nuestro compromiso es utilizar técnicas modernas que además protegen el medio ambiente.<br></br>.<br></br> .<br></br> .<br></br> .</p>
            {/* <button className="btn__proyecto">View Trips</button> */}
          </div>
        </div>
        <div className="card__proyecto">
          <div className="content__proyecto">
            <h2 className="title__proyecto">TRANSPARENCIA</h2>
            <p className="copy__proyecto">Desarrollamos una constante interacción con nuestros clientes e inversores a través de muestreos regulares del avance de proyecto haciendo foco en el cumplimiento de los plazos establecidos.</p>
            {/* <button className="btn__proyecto">View Trips</button> */}
          </div>
        </div>
        <div className="card__proyecto">
          <div className="content__proyecto">
            <h2 className="title__proyecto">PRECIO JUSTO</h2>
            <p className="copy__proyecto">Garantizamos un cuidadoso proceso de selección de proveedores de servicios y profesionales idóneos que aseguran la calidad en cada detalle.<br></br>.<br></br>.</p>
            {/* <button className="btn__proyecto">Book Now</button> */}
          </div>
        </div>
        <div className="card__proyecto">
          <div className="content__proyecto">
            <h2 className="title__proyecto">SATISFACCIÓN</h2>
            <p className="copy__proyecto">La sumatoria de calidad, respeto, seguridad y confianza en nuestro servicio asegura la satisfacción de nuestros clientes al culminar cada proyecto.<br></br>.<br></br>.</p>
            {/* <button className="btn__proyecto">Book Now</button> */}
          </div>
        </div>
      </div>
        
      </div>
    
    
    </>
  );
};
