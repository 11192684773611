import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getProyectos, getProyectos2, getUsers } from '../../redux/actions'
import './adminpanel.css'
import { Proyectosadmin } from './Proyectosadmin'
import { Suscriptoresadmin } from './Suscriptoresadmin'

export const Adminpanel = () => {
    const dispatch = useDispatch()

    const [seleccion, setSeleccion] = useState(1)

    const suscriptores = useSelector((state) => state.Suscriptores);
    const proyectos = useSelector((state) => state.Proyectos);
    const [rec, setRec] = useState(1)


    useEffect(() => {
        dispatch(getUsers())
        dispatch(getProyectos2())
    }, [])
    




  return (
    <div className="divprinpaneladmin">
      <div className='adminpanel'>
        <div className="sidbarpaneladmin">
          <img src="../../image/logocompleto.png" className='logo__paneladmin' alt="" />
          <button className={seleccion === 1 ? 'boton__paneladmin btn btn-primary':'boton__paneladmin btn btn-info'} onClick={()=>{setSeleccion(1);setRec(rec + 1)}}>Proyectos</button>
          <button className={seleccion === 2 ? 'boton__paneladmin btn btn-primary':'boton__paneladmin btn btn-info'} onClick={()=>setSeleccion(2)}>Suscriptores</button>
        </div>
        <div className="contenidopaneladmin">
          {seleccion === 1 ?
          <Proyectosadmin proyectos={{proyectos,rec}}></Proyectosadmin>
          
          :<Suscriptoresadmin suscriptores={suscriptores}></Suscriptoresadmin>  }
        </div>
        
      </div>

    </div>
  )
}
